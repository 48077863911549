import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setVariable } from "../../../slice/trialSlice";
const EditTrialVariables = (props) => {
  let { open, handleClose, index, data } = props;

  const { current_trial } = useSelector((state) => ({
    current_trial: state?.trial?.current_trial,
  }));
  const dispatch = useDispatch();

  const handleClick = (type) => {
    // dispatch(imageRmove(""));
    switch (type) {
      case "close":
        handleClose();

        break;

      default:
        break;
    }
  };
  return (
    <Dialog open={open}>
      <DialogTitle variant="body">Schedule</DialogTitle>
      <DialogContent>
        <div className="form-group mt-5">
          <label htmlFor="categoryId" className="fw-bold mb-2">
            Name
          </label>
          <select
            className="form-control"
            style={{ width: "100%", padding: 10 }}
            name="inputType"
            onChange={(e) => {
              dispatch(
                setVariable({
                  index: index,
                  type: "selete",
                  // dIndex: i,
                  value: e.target.value,
                })
              );
            }}
            // onBlur={handleBlur}
            // value={values?.inputType}
          >
            <option value={current_trial?.variables[index]?.name}>
              {current_trial?.variables[index]?.name}
            </option>
            {data?.list?.map((item, index) => (
              <option value={item.name} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <>
          {current_trial?.variables[index]?.schedule?.map((item, i) => (
            <Box
              className={`row ${i % 2 == 0 && "bg-light"}`}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              key={i}
              paddingY={"10px"}
              borderBottom={"2px solid #ccc"}
              width={"100%"}
              gap={"5px"}
              flexWrap={"wrap"}
            >
              <Box>
                <input
                  type="text"
                  // name={`options.${index}.label`}
                  value={item}
                  className="form-control"
                  style={{
                    marginTop: "0px",
                  }}
                  onChange={(e) =>
                    dispatch(
                      setVariable({
                        index: index,
                        type: "edite",
                        dIndex: i,
                        value: e.target.value,
                      })
                    )
                  }
                  placeholder="Enter Schedule"
                />
                {/* <Box color={"red"}>
                  <ErrorMessage name={`options.${index}.label`} />
                </Box> */}
              </Box>
              {/* <Box>
                <Field
                  type="text"
                  placeholder="Enter Value"
                  name={`options.${index}.value`}
                  style={{
                    marginTop: "0px",
                  }}
                  className="form-control"
                />
                <Box color={"red"}>
                  <ErrorMessage name={`options.${index}.value`} />
                </Box>
              </Box> */}
              <Box>
                <i
                  class="fa-solid fa-trash trash-btn"
                  onClick={() => {
                    dispatch(
                      setVariable({ index: index, type: "delete", dIndex: i })
                    );
                  }}
                ></i>
              </Box>

              {/* </div> */}
              <hr />
            </Box>
          ))}
        </>
        <Button
          variant="contained"
          className="add_btn"
          style={{ marginTop: "20px" }}
          //   margin={"10px 0px"}
          //  color="#556B2F"
          onClick={() => {
            // boundArrayHelpers.push({
            //   label: "",
            //   value: "",
            // });
            dispatch(setVariable({ index: index, type: "add" }));
          }}
        >
          Add
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClick("close")}>Close</Button>
        {/* <Button
          variant="contained"
          type="submit"
          // onClick={() => handleClick("save")}
        >
          Save
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default EditTrialVariables;
