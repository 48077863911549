import { ReactComponent as User } from "../assets/img/icons/font-icon-done/user.svg";
import { ReactComponent as Dashboard } from "../assets/img/icons/font-icon-done/dashboard.svg";
import { config } from "./config";

export default {
  SIDE_MENU: [
    {
      id: 0,
      title: "Dashboard",
      icon: "fa-solid fa-table-columns",
      link: "/dashboard",
    },
    {
      id: 1,
      title: "Manage User",
      icon: "fa-solid fa-user",
      link: "/users",
    },
    // {
    //   id: 2,
    //   title: "Manage Admin",
    //   // icon: <User width={15} />,
    //   link: "/admin-user",
    // },
    {
      id: 3,
      title: "Manage Category",
      icon: "fa-solid fa-bars",
      link: "/category",
    },
    {
      id: 4,
      title: "Manage Goals",
      icon: "fa-solid fa-bullseye",
      link: "/goals",
    },
    {
      id: 5,
      title: "Manage Interventions",
      icon: "fa-solid fa-users",
      link: "/intervention",
    },
    {
      id: 6,
      title: "Manage Variable",
      icon: "fa-solid fa-comment-dots",
      link: "/variable",
    },
    {
      id: 7,
      title: "Reports",
      icon: "fa fa-flag",
      link: "/report",
    },
    {
      id: 8,
      title: "Trials",
      icon: "fa fa-stethoscope",
      link: "/trials",
    },
    // {
    //   id: 4,
    //   title: "Manage Variables",
    //   icon: <User width={15} />,
    //   link: "/users",
    // },
  ],
  MESSAGES: {
    ADD: "Added Successfully",
    CAT_EDIT: "Category edited Successfully",
    CAT_DEL: "Category deleted Successfully",

    GOAL_ADD: "Goal Added Successfully",
    Goal_EDIT: "Goal edited Successfully",
    GOAL_DEL: "Goal deleted Successfully",

    USERs_ADD: "User Added Successfully",
    USER_EDIT: "User edited Successfully",
    USER_DEL: "User deleted Successfully",

    Inter_ADD: "Intervension Added Successfully",
    INTER_EDIT: "Intervension edited Successfully",
    INTER_DEL: "Intervension deleted Successfully",

    VARIABLE_ADD: "Variable Added Successfully",
    VARIABLE_EDIT: "Variable edited Successfully",
    VARIABLE_DEL: "Variable deleted Successfully",

    LOGOUT: "Logout Successfully",

    REPORT_ACTION_SUCCESS: "Action on reported content successfully!",
    REPORT_ERROR: "Something wrong! Please Try again after some time!",
  },
  URL: {
    baseUrl: "https://nof1.app/backend/v1/admin",
    // imageUrl: "http://54.201.160.69:3267",
    imageUrl: config.imageUrl
  },
};
