import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { CategorySchema } from "../../../helpers/Validation";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Form, Formik, ErrorMessage } from "formik";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { setCurrentCategory } from "../../../slice/categorySlice";
import ErrorImage from "../../../assets/img/errorImage.jpg";
import { addVariable, editVariable } from "../../../slice/variableSlice";
import GLOBALS from "../../../constants";

import {
  addUserData,
  imageUserRmove,
  uploadImage,
  setCurrentUser,
  editUser,
} from "../../../slice/usersSlice";
const { URL } = GLOBALS;
const EditAdminUser = (props) => {
  let { open, handleClose, onEditPerform } = props;
  // const currentItem = useSelector((state) => state.category.current_category);
  const dispatch = useDispatch();
  const { imageGet, currentItem } = useSelector((state) => ({
    imageGet: state.users.image,
    currentItem: state.users.current_user,
  }));
  // const [data, setData] = useState(currentItem);
  // const [list, setCategoryList] = useState([]);
  useEffect(() => {}, [props]);
  const handleClick = (type) => {
    dispatch(imageUserRmove(""));
    switch (type) {
      case "close":
        handleClose();

        break;

      default:
        break;
    }
  };
  const handleFormSubmit = (values) => {
    // onEditPerform({ type: "perform_edit", values: values });
    if (currentItem?._id) {
      // alert("1");
      dispatch(editUser({ id: currentItem._id, values, dispatch }));
      dispatch(imageUserRmove(""));
    } else {
      dispatch(addUserData({ values, dispatch, type: "admin" }));
      dispatch(imageUserRmove(""));
    }
    // if (currentItem?._id) {
    //   onEditPerform({ type: "perform_edit", values: values });
    //   dispatch(imageRmove(""));
    // } else {
    //   onEditPerform({ type: "perform_add", values: values });
    //   dispatch(imageRmove(""));
    // }
    handleClick("close");
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          image: imageGet || currentItem?.image || "",
          name: currentItem?.name || "",
          email: currentItem?.email || "",
          //   details: currentItem?.details || "",
          // avatar: imageGet || data?.result?.avatar,
        }}
        onSubmit={handleFormSubmit}
        validationSchema={CategorySchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          errors,
          touched,
        }) => (
          <Dialog open={open}>
            <Form>
              <DialogTitle variant="body">
                {Object.keys(currentItem).length === 0 ? "Add" : "Edit"} Details
              </DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    width: "500px",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div className="border-top border-info border-3 bg-white mt-3 p-2">
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      value={values.name}
                      name="name"
                      label="Admin Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      fullWidth
                    />
                    <Box color={"red"}>
                      <ErrorMessage name="name" />
                    </Box>

                    <TextField
                      multiline={true}
                      autoFocus
                      margin="dense"
                      id="email"
                      value={values.email}
                      name="email"
                      label="Admin Email"
                      onChange={handleChange}
                      type="text"
                      fullWidth
                    />
                    {imageGet == "" ? (
                      <img
                        className="img-fluid max_img_view_admin"
                        src={
                          currentItem.image || values?.image != ""
                            ? `${URL.imageUrl}/${currentItem?.image}`
                            : ErrorImage
                        }
                        alt="blog dd"
                      />
                    ) : (
                      <img
                        className="img-fluid max_img_view_admin"
                        src={`${URL.imageUrl}/${imageGet}`}
                        alt="blog"
                      />
                    )}
                    {/* <img
                      className="img-fluid max_img_view_admin"
                      src={`http://54.201.160.69:3266/static/media/errorImage.7c118d410059de3c522d.jpg`}
                      alt="blog"
                    /> */}
                    <div className="form-group">
                      <label htmlFor="featured_image" className="fw-bold mb-2">
                        Image
                      </label>
                      <input
                        type="file"
                        name="featured_image"
                        className="form-control"
                        id="featured_image"
                        accept="image/*"
                        // value={values.featured_image}
                        // onBlur={handleBlur}
                        onChange={(e) => {
                          // image(e);
                          dispatch(uploadImage(e.target.files[0]));
                          dispatch(
                            setCurrentUser({
                              image: "",
                              name: values?.name,
                              email: values?.email,
                              _id: currentItem?._id,
                            })
                          );
                          // setFieldValue("featured_image", e.target.value);
                          // uploadFile(e);
                          // handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleClick("close");
                    dispatch(setCurrentUser({}));
                  }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  // onClick={() => handleClick("save")}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default EditAdminUser;
