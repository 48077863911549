export const timeStampToHHMMstring = (timestamp) => {
  const dateObj = new Date(timestamp);
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
  return formattedTime;
};

export const hhMMTotimeStamp = (time) => {
  if (!time || time == "") return "";

  const dateObj = new Date();
  let hours = time?.split(":")[0];
  let minutes = time?.split(":")[1];
  dateObj.setHours(hours);
  dateObj.setMinutes(minutes);
  return dateObj.getTime();
};
