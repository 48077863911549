import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import CIcon from "@coreui/icons-react";
import { cilJustifyCenter, cilPencil, cilTrash } from "@coreui/icons";
import { CButton } from "@coreui/react";
import IconButton from "@mui/material/IconButton";
import FitbitIcon from "@mui/icons-material/Fitbit";
import frtSvg from "../../../assets/frontArrow.svg";
import bckSvg from "../../../assets/backArrow.svg";
import adminB from "../../../assets/img/admin_b.svg";
import Table from "@mui/material/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonIcon from "@mui/icons-material/Person";
import { deleteGoal, goalList } from "../../../slice/goalSlice";
import ReactPaginate from "react-paginate";
import {
  setCurrentVariable,
  deleteVariable,
  variableList,
} from "../../../slice/variableSlice";
import DeleteModal from "../../common/DeleteModal";
import Pagination from "../../common/Pagination";
import { reportList } from "../../../slice/reportSlice";
import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import reportContentObject from "../../../helpers/reportContentObject";
import moment from "moment/moment";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ReportList(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { all_report } = useSelector((state) => ({
    all_report: state.report.all_report,
  }));
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
    // fetchData(data.selected + 1, "");
    dispatch(
      reportList({
        page: data?.selected + 1,
        limit: 10,
        status: "all",
      })
    );
  };

  const handlePageClickEvent = (page) => {
    // props.setPage(page);
    setCurrentPage(page);
    // setDummyReload(new Date().toLocaleTimeString());
    // fetchData(data.selected + 1, "");
    dispatch(
      reportList({
        page: page,
        limit: 10,
        status: props.status,
      })
    );
  };
  useEffect(() => {
    dispatch(
      reportList({
        page: 1,
        limit: 10,
        status: props.status,
      })
    );
  }, []);

  useEffect(() => {
    if (all_report) {
      setList(all_report.list);
      setPageCount(Math.ceil(all_report?.count / 10));
      // setCurrentPage(1);
    }
  }, [all_report]);

  useEffect(() => {
    setCurrentPage(1);
  }, [props.status]);

  return (
    <Grid item xs={6} sm={6} md={9}>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow sx={{ color: "red" }}>
              <StyledTableCell align="left">Sr. No</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              {/* <StyledTableCell align="left">Content</StyledTableCell> */}
              <StyledTableCell align="left">Reason Type</StyledTableCell>
              <StyledTableCell align="left">No. Of Reports</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left">Reported Date</StyledTableCell>
              <StyledTableCell align="left">View</StyledTableCell>
              {/* <StyledTableCell align="left">Delete</StyledTableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {list?.length === 0 ? (
              <>
                <TableRow>
                  <TableCell
                    colSpan={7}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    No result found
                  </TableCell>
                </TableRow>
              </>
            ) : list?.map((item, index) => {
              // AdminPanelSettingsIcon;

              return (
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    {(currentPage - 1) * 10 + index + 1}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <span>
                      {reportContentObject[item?.reportContent?.type]["title"]}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {[
                      ...new Set(item.reportedUser.map((c) => c.reasonType)),
                    ].map((reasonType) => {
                      return (
                        <Chip className="report-chip" label={reasonType} />
                      );
                    })}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <span>{item?.reportedUser?.length || 0}</span>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item?.reportContent?.status}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {moment(item?.reportContent?.createdAt).format("llll")}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <IconButton
                      onClick={() => {
                        navigate(`/report/${item.reportContent?._id}`);
                      }}
                      title="View"
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
          {/* )} */}
        </Table>
      </TableContainer>
      {pageCount && pageCount > 1 ? (
        <div className="myPaginationBottom">
          <Pagination
            totalPage={pageCount}
            currentActionPage={currentPage}
            previousIcon={bckSvg}
            nextIcon={frtSvg}
            defaultActivePage={currentPage}
            onPageChange={handlePageClick}
            onhandlePageChange={handlePageClickEvent}
          />
        </div>
      ) : (
        <></>
      )}
      {/* <DeleteModal
        isOpen={open}
        handleClose={handleClose}
        onDelete={onDelete}
      /> */}
    </Grid>
  );
}

export default ReportList;
