import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ClearIcon from "@mui/icons-material/Clear";
import {
  TextField,
  Container,
  Box,
  Button,
  IconButton,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import SideBar from "../../common/SideBar";
import Header from "../../common/Header";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAdminCategoryList } from "../../../slice/categorySlice";
import { useNavigate } from "react-router-dom";
import GLOBALS from "../../../constants";
import InterventionDialogue from "./InterventionDialogue";
import { getAdminInterventionList } from "../../../slice/interventionSlice";
import {
  addTrial,
  setAdminCurrentTrial,
  setCurrentTrialField,
  setIntervention,
  setVariable,
  uploadImageForAdminTrial,
} from "../../../slice/adminTrialSlice";
import { getAdminGoalList } from "../../../slice/goalSlice";
import VariableDialogue from "./VariableDialogue";
import { showErrorToast, showSuccessToast } from "../../../helpers/common";
import { ToastContainer, toast } from "react-toastify";
import TrialPreview from "./TrialPreview";
import { getAdminVariableList } from "../../../slice/variableSlice";
const { URL: constants } = GLOBALS;

// import { set } from "imme`r/dist/internal";
function AddAdminTrial(props) {
  const characters = ["A", "B", "C", "D", "E"];

  const [toggle, setToggle] = useState(false);
  const [isInterventionOpen, setIsInterventionOpen] = useState(false);
  const [isVariableOpen, setIsVariableOpen] = useState(false);
  const [selectedInterventionIndex, setSelectedInterventionIndex] = useState(
    undefined
  );
  const [selectedVariableIndex, setSelectedVariableIndex] = useState(undefined);
  const [selectedCategoryImage, setSelectedCategoryImage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [addIntervention, setAddIntervention] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { admin_current_trial, category_list, goal_list } = useSelector(
    (store) => ({
      admin_current_trial: store.admin_trial.admin_current_trial,
      category_list: store.category.all_categories.list,
      goal_list: store.goal.all_goal.list,
    })
  );

  useEffect(() => {
    dispatch(getAdminCategoryList({}));
  }, []);

  const handleTrialFieldChange = (trialKey, trialValue) => {
    if (trialValue) {
      if (trialKey == "phaseDuration" && trialValue > 7) {
        toast.error("Maximum 7 days allowd!");
        return false;
      }
      if (trialKey == "phaseNumber" && trialValue > 5) {
        toast.error("Maximum 5 phase allowd!");
        return false;
      }
      if (trialKey == "phaseDuration" && trialValue < 1) {
        toast.error("Minimum 1 day is required");
        return false;
      }
      if (trialKey == "phaseNumber" && trialValue < 1) {
        toast.error("Minimum 1 phase is required");
        return false;
      }
    }
    dispatch(
      setCurrentTrialField({
        trial_field_key: trialKey,
        trial_field_value: trialValue,
      })
    );
    dispatch(getAdminVariableList({}));
  };
  const handleCategoryChange = (categoryId) => {
    handleTrialFieldChange("goal", "");
    handleTrialFieldChange("intervention", []);
    handleTrialFieldChange("variables", []);
    dispatch(getAdminGoalList({ categoryId }));
    dispatch(getAdminInterventionList({ categoryId }));
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setImageSrc(URL.createObjectURL(event.target.files[0]));
    dispatch(uploadImageForAdminTrial(event.target.files[0]));
  };

  const handleSubmit = () => {
    let obj = { ...admin_current_trial };
    obj["interventionIsCompare"] = obj.intervention.length == 2 ? true : false;
    obj["intervention"] = obj.intervention.map((item, index) => {
      return {
        id: item._id,
        perDayCount: item.schedule.length,
        schedule: item.schedule,
        tag: characters[index],
      };
    });
    obj["variables"] = obj.variables.map((item) => {
      return {
        id: item.id,
        schedule: item.schedule,
        perDayCount: item.schedule.length,
      };
    });

    obj["isPublic"] = true;
    for (let val in obj) {
      if (val !== "image" && val !== "isPublic") {
        if (obj[val]) {
          if (typeof obj[val] === "object") {
            if (obj[val].length < 1) {
              showErrorToast(`Fill ${val} details properly`);
            } else {
              dispatch(addTrial({ input: obj }));
              setTimeout(() => {
                dispatch(
                  setAdminCurrentTrial({
                    category: "",
                    idea: "",
                    image: "",
                    intervention: [],
                    isPublic: true,
                    name: "",
                    phaseDuration: 7,
                    phaseNumber: 1,
                    phasePattern: "test",
                    variables: [],
                  })
                );
                navigate("/admin-trial");
              }, 3000);
            }
          }
        } else {
          showErrorToast(`Fill ${val} details properly`);
        }
      }
    }
  };
  const handleClear = () => {
    setSelectedFile(null);
    setImageSrc(null);
  };

  return (
    <div className="dashboard-wrapper d-flex flex-column flex-fill overflow-auto">
      <Header setToggle={setToggle} toggle={toggle} />
      <div className="inner-wrapper">
        <div
          className={`left-col d-flex flex-column  ${
            toggle ? "collapseSidebar" : ""
          } `}
        >
          <SideBar setToggle={setToggle} toggle={toggle}></SideBar>
        </div>
        <div
          className={`right-col d-flex flex-column ${
            toggle ? "expandDashboard" : ""
          }`}
        >
          <Container
            maxWidth="md"
            style={{ textAlign: "center" }}
            className="trialcontainer"
          >
            <Box
              sx={{
                textAlign: "start",
                marginBottom: "0.9em",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                cursor: "pointer",
                width: "20%",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIosIcon />
              <Box component="span" sx={{ fontSize: "25px" }}>
                Back
              </Box>
            </Box>
            <div className="trial-header">
              {/* <img
                width="70px"
                height="50px"
                style={{ borderRadius: "50%" }}
                src={require("../../../assets/img/profile.png")}
                alt=""
              /> */}
              <span>Trial</span>
            </div>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: "auto", width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2}>
                <Grid xl={8} lg={8} md={8} sm={8} xs={8} item>
                  <div className="contentBox">
                    <TextField
                      label="Name"
                      defaultValue="Normal"
                      value={admin_current_trial?.name || ""}
                      onChange={(e) => {
                        handleTrialFieldChange("name", e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="contentBox">
                    <TextField
                      label="Idea"
                      defaultValue="Ad"
                      value={admin_current_trial?.idea || ""}
                      onChange={(e) => {
                        handleTrialFieldChange("idea", e.target.value);
                      }}
                      required
                    />
                  </div>
                </Grid>
                <Grid xl={4} lg={4} md={4} sm={4} xs={4} item>
                  {/* <img
                    src={require("../../../assets/img/errorImage.jpg")}
                    width="100%"
                    height={"100%"}
                    alt=""
                    className="trialImage"
                  /> */}

                  <div className="file-upload">
                    {selectedFile && (
                      <button className="clear-icon" onClick={handleClear}>
                        <ClearIcon />
                      </button>
                    )}

                    <label htmlFor="file-input">
                      {selectedFile ? "Change file" : "Select file"}
                    </label>

                    <input
                      id="file-input"
                      type="file"
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                    {imageSrc && (
                      <div className="file-preview">
                        <img src={imageSrc} alt="Selected file" />
                        <span>{selectedFile.name}</span>
                      </div>
                    )}
                    {/* <button onClick={handleUpload} disabled={!selectedFile}>
                      Upload
                    </button> */}
                  </div>
                </Grid>
              </Grid>

              <div className="contentBox category-container">
                <h3>Category</h3>
                <div className="capsule-container">
                  <div className="capsule-image">
                    <img
                      src={
                        selectedCategoryImage
                          ? `${constants.imageUrl}/${selectedCategoryImage}`
                          : require("../../../assets/img/errorImage.jpg")
                      }
                      alt="category img"
                    />
                  </div>
                  <div className="capsule-dropdown">
                    <select
                      className="form-control"
                      style={{ width: "100%", padding: 10 }}
                      name="inputType"
                      onChange={(e) => {
                        // handleChangeImage(e.target.value);
                        let category = JSON.parse(e.target.value);
                        setSelectedCategoryImage(category.avatar);
                        handleTrialFieldChange("category", category._id);
                        handleCategoryChange(category._id);
                      }}
                      // onBlur={handleBlur}
                      // value={values?.inputType}
                    >
                      <option value="">Select Category</option>
                      {category_list &&
                        category_list.map((category) => {
                          return (
                            <option value={JSON.stringify(category)}>
                              {" "}
                              {category.name}{" "}
                            </option>
                          );
                        })}
                    </select>
                    {/* <div style={{ padding: "1em" }}>\/</div> */}
                  </div>
                </div>
              </div>
              <div className="goal-container">
                <div>
                  <h3>Goal</h3>
                </div>
                <div className="goal-dropdown">
                  <select
                    className="form-control"
                    style={{ width: "100%", padding: 10 }}
                    name="inputType"
                    disabled={!admin_current_trial?.category}
                    onChange={(e) => {
                      // handleChangeImage(e.target.value);
                      handleTrialFieldChange("goal", e.target.value);
                    }}
                    // onBlur={handleBlur}
                    // value={values?.inputType}
                  >
                    {/* <option value={current_trial?.category?.name}>
                        {current_trial?.category?.name}
                      </option>
                      {categoryData?.list?.map((item, index) => (
                        <option value={item.name} key={index}>
                          {item.name}
                        </option>
                      ))} */}
                    <option value="">Select Goal</option>
                    {goal_list &&
                      goal_list.map((goal, index) => {
                        return (
                          <option key={index} value={goal._id}>
                            {goal.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="contentBox intervention-container">
                <div className="intervention-header">
                  <h3>Intervention</h3>
                  <Button
                    className="intervention-add-button"
                    variant="contained"
                    component="label"
                    disabled={
                      (admin_current_trial &&
                        admin_current_trial.intervention &&
                        admin_current_trial.intervention.length >= 2) ||
                      !admin_current_trial?.category ||
                      !admin_current_trial?.goal ||
                      (admin_current_trial.intervention.length == 1 &&
                        !addIntervention)
                    }
                    onClick={() => {
                      if (
                        admin_current_trial &&
                        admin_current_trial.intervention &&
                        admin_current_trial.intervention.length >= 2
                      ) {
                        // alert("Intervention cannot compare more then 1");
                        showErrorToast(
                          "Intervention cannot compare more then 1"
                        );
                      } else {
                        setIsInterventionOpen(true);
                      }
                    }}
                  >
                    ADD
                  </Button>
                </div>

                <div className="intervention-list-container">
                  <ul>
                    <li>
                      {admin_current_trial?.intervention.map(
                        (intervention, index) => {
                          return (
                            <>
                              <Box
                                className="intervensionList"
                                sx={{ my: "5px" }}
                              >
                                {/* <div className="tag">{intervention.tag}</div> */}

                                <Box
                                  sx={{ paddingLeft: "1em", width: "10%" }}
                                  className="name"
                                >
                                  <p>{characters[index]}</p>
                                </Box>
                                <Box sx={{ width: "60%" }} className="name">
                                  <p>{intervention?.name}</p>
                                </Box>
                                <Box
                                  width={"30%"}
                                  className="name"
                                  color={"#fff"}
                                >
                                  {intervention?.schedule.join(", ")}
                                </Box>

                                <div>
                                  <IconButton
                                    onClick={() => {
                                      setIsInterventionOpen(true);
                                      setSelectedInterventionIndex(index);
                                    }}
                                    className="trash-icon"
                                    aria-label="trial"
                                    // disabled
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      // setIsInterventionOpen(true);
                                      // setSelectedInterventionIndex(index);
                                      dispatch(
                                        setIntervention({
                                          index: index,
                                          type: "delete",
                                        })
                                      );
                                      setAddIntervention(false);
                                    }}
                                    className="trash-icon"
                                    aria-label="trial"
                                    disabled={!index}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </Box>
                              {!index && (
                                <div style={{ textAlign: "initial" }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={(e) => {
                                          if (
                                            admin_current_trial?.intervention
                                              .length > 1
                                          ) {
                                            dispatch(
                                              setIntervention({
                                                index: index,
                                                type: "delete",
                                              })
                                            );
                                          } else {
                                            setIsInterventionOpen(true);
                                          }
                                          setAddIntervention(e.target.checked);
                                        }}
                                        checked={addIntervention}
                                      />
                                    }
                                    label="Do you want to compare this intervention with another?"
                                  />
                                </div>
                              )}
                            </>
                          );
                        }
                      )}
                    </li>
                  </ul>
                </div>

                {isInterventionOpen && (
                  <InterventionDialogue
                    open={isInterventionOpen}
                    index={selectedInterventionIndex}
                    handleInterventionIndex={setSelectedInterventionIndex}
                    handleClose={() => {
                      setIsInterventionOpen(false);
                      // setAddIntervention(false)
                    }}
                  />
                )}
              </div>
              <div className="contentBox variable-container ">
                <div className="variable-header">
                  <h3>Variables</h3>

                  <Button
                    className="intervention-add-button"
                    variant="contained"
                    component="label"
                    disabled={
                      !admin_current_trial?.category ||
                      !admin_current_trial?.goal
                    }
                    onClick={() => setIsVariableOpen(true)}
                  >
                    ADD
                  </Button>
                </div>
                <div className="intervention-list-container">
                  <ul>
                    <li>
                      {admin_current_trial?.variables.map((variable, index) => {
                        return (
                          <Box className="intervensionList" sx={{ my: "5px" }}>
                            {/* <div className="tag">{intervention.tag}</div> */}
                            <Box sx={{ width: "60%" }} className="name">
                              <p>{variable?.name}</p>
                            </Box>
                            <Box width={"20%"} className="name" color={"#fff"}>
                              {variable?.schedule.join(", ")}
                            </Box>

                            <div>
                              <IconButton
                                onClick={() => {
                                  setIsVariableOpen(true);
                                  setSelectedVariableIndex(index);
                                }}
                                className="trash-icon"
                                aria-label="trial"
                                // disabled
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  // setIsInterventionOpen(true);
                                  // setSelectedInterventionIndex(index);
                                  dispatch(
                                    setVariable({
                                      index: index,
                                      type: "delete",
                                    })
                                  );
                                }}
                                className="trash-icon"
                                aria-label="trial"
                                // disabled
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </Box>
                        );
                      })}
                    </li>
                  </ul>
                </div>

                {isVariableOpen && (
                  <VariableDialogue
                    open={isVariableOpen}
                    index={selectedVariableIndex}
                    handleVariableIndex={setSelectedVariableIndex}
                    handleClose={() => {
                      setIsVariableOpen(false);
                    }}
                  />
                )}
                <ul>
                  {/* {current_trial?.variables?.map((variable, index) => {
                    return (
                      <li>
                        <div className="variableList">
                          <Box className="data" width={"60%"}>
                            <p>{variable.name}</p>
                          </Box>
                          <Box className="data" width={"20%"}>
                            <InputLabel
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              <Box fontWeight={"600"}>{variable.inputType}</Box>
                            </InputLabel>
                          </Box>
                          <Box className="data" width={"20%"}>
                            {variable?.schedule.join(", ")}
                          </Box>
                          <div>
                            <IconButton
                              onClick={() => {
                                // onDelete(item);
                                setIsVOpen(true);
                                setVIndex(index);
                                // navigate(`/users/trial-list/${user?._id}`);
                              }}
                              disabled
                              className="trash-icon"
                              aria-label="trial"
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        </div>
                      </li>
                    );
                  })} */}
                </ul>
              </div>
              <div className="contentBox">
                <div className="phases-container ">
                  <div>
                    <div className="phaseDuration">
                      <TextField
                        label="Phase Duration(days)"
                        fullWidth
                        onChange={(e) => {
                          handleTrialFieldChange(
                            "phaseDuration",
                            e.target.value
                          );
                        }}
                        value={admin_current_trial?.phaseDuration}
                      />
                    </div>
                    <div className="phaseNumber">
                      <TextField
                        label="Number of Phase Pairs"
                        fullWidth
                        onChange={(e) => {
                          handleTrialFieldChange("phaseNumber", e.target.value);
                        }}
                        value={admin_current_trial?.phaseNumber}
                      />
                    </div>
                  </div>

                  <div className="phase-pattern">
                    <select
                      name="phasePattern"
                      id=""
                      className="form-control"
                      label="Phase Pattern"
                      style={{ width: "100%", padding: 10 }}
                      value={admin_current_trial.phasePattern}
                      onChange={(e) => {
                        handleTrialFieldChange("phasePattern", e.target.value);
                      }}
                    >
                      <option value="">Select Phase Pattern</option>
                      <option value="alter">Alternating [ABAB]</option>
                      <option value="test">Simple [ABBA]</option>
                    </select>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "2em" }}>
                <Button
                  variant="outlined"
                  size="large"
                  color="success"
                  onClick={() => setOpenPreview(true)}
                  disabled={admin_current_trial.intervention.length < 1}
                >
                  Show Preview
                </Button>
              </div>
            </Box>
          </Container>
        </div>
      </div>

      {/* <EditTrialVariables
        key={isVOpen}
        index={VIndex}
        handleClose={() => setIsVOpen(false)}
        open={isVOpen}
        data={variableData}
      /> */}
      {openPreview && (
        <TrialPreview
          open={openPreview}
          onOk={handleSubmit}
          onClose={() => setOpenPreview(false)}
          data={admin_current_trial}
        />
      )}
      <ToastContainer />
    </div>
  );
}

export default AddAdminTrial;
