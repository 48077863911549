import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../helpers/RestClient";
import * as apiImage from "../helpers/ImageCommon";
import Chart from "react-apexcharts";

import {
  encrypt,
  decrypt,
  showSuccessToast,
  showErrorToast,
} from "../helpers/common";
import GLOBALS from "../constants";

const { MESSAGES } = GLOBALS;

export const trialData = createAsyncThunk(
  "user-manage/users/:userId/trials",
  async ({ id }, { dispatch }) => {
    try {
      const response = await api.trialManagment(id);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        // dispatch(setUserList(decrypt_data.result));
        return decrypt_data.result;
      }
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

export const setCurrentTrialOfUser = createAsyncThunk(
  "/trialId",
  async ({ userId, trialId }) => {
    try {
      const response = await api.getTrialById(userId, trialId);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        // dispatch(setUserList(decrypt_data.result));
        return decrypt_data.result;
      } else {
        showErrorToast(decrypt_data.message);
      }
    } catch (err) {
      showErrorToast(err?.message || "Internal Server Error");
    }
  }
);

const trialSlice = createSlice({
  name: "trial",
  initialState: {
    user_all_trial: {},
    current_trial: {
      _id: "63995760bde57270b19be20b",
      createdAt: "2022-12-14T04:56:00.741Z",
      trialId: "63995233bde57270b19be028",
      name: "re",
      idea: "e",
      image: "",
      isPublic: true,
      category: {
        id: "636c8f42c09eeb2d71ea4968",
        name: "Weight Loss",
        avatar: "images/1668058934402.jpeg",
      },
      intervention: [
        {
          id: "636c91449c36640bd17d2d4b",
          name: "1Willow bark tea",
          desc: "Willow bark tea1",
          backgroundLiterature: "Willow bark tea",
          tag: "A",
          perDayCount: 1,
          schedule: ["10:03"],
          _id: "63995233bde57270b19be029",
        },
        {
          name: "Without 1Willow bark tea",
          tag: "B",
          perDayCount: 1,
          schedule: ["10:03"],
          _id: "63995233bde57270b19be02a",
        },
      ],
      variables: [
        {
          id: "63749b8d72d6e9746aed878a",
          name: "Blood Pressure",
          inputType: "input",
          fields: {
            _id: "63749b8d72d6e9746aed878a",
            name: "Blood Pressure",
            inputType: "input",
            createdBy: "63485b412efe27e8538f78b0",
            isUserSpecific: false,
            minValue: 0,
            maxValue: 100,
            minLabel: "Worst",
            maxLabel: "Good",
            options: [
              {
                label: "Good",
                value: "5",
                _id: "63749b8d72d6e9746aed878b",
              },
              {
                label: "Better",
                value: "4",
                _id: "63749b8d72d6e9746aed878c",
              },
            ],
            isEditable: true,
            unit: "mg/dL",
            unitType: "weight",
            createdAt: "2022-11-16T08:13:01.701Z",
            updatedAt: "2022-11-16T08:13:01.701Z",
            __v: 0,
          },
          perDayCount: 1,
          schedule: ["10:03"],
          _id: "63995233bde57270b19be02b",
        },
      ],
      userName: "yes",
      startDate: "2022-12-14T04:56:00.739Z",
      endDate: "2023-01-10T04:56:00.738Z",
      phaseDuration: 7,
      phaseNumber: 2,
      phasePattern: "alter",
    },

    trialData: null,
  },
  reducers: {
    setCurrentTrial: (state, action) => {
      state.current_trial = action.payload;
    },
    setSchedule: (state, action) => {
      if (action.payload.type == "add") {
        state.current_trial.intervention[action?.payload.index].schedule.push(
          ""
        );
      } else if (action.payload.type == "delete") {
        state.current_trial.intervention[action?.payload.index].schedule.splice(
          action?.payload.dIndex,
          1
        );
      } else if (action.payload.type == "edite") {
        state.current_trial.intervention[action?.payload.index].schedule[
          action.payload.dIndex
        ] = action.payload.value;
      }
    },
    setVariable: (state, action) => {
      if (action.payload.type == "add") {
        state.current_trial.variables[action?.payload.index].schedule.push("");
      } else if (action.payload.type == "delete") {
        state.current_trial.variables[action?.payload.index].schedule.splice(
          action?.payload.dIndex,
          1
        );
      } else if (action.payload.type == "edite") {
        state.current_trial.variables[action?.payload.index].schedule[
          action.payload.dIndex
        ] = action.payload.value;
      } else if (action.payload.type == "selete") {
        state.current_trial.variables[action?.payload.index].name =
          action.payload.value;
      }
    },
  },
  extraReducers: {
    [trialData.fulfilled]: (state, action) => {
      state.user_all_trial = action.payload;
      //   state.loading = false;
    },
    [setCurrentTrialOfUser.fulfilled]: (state, action) => {
      state.trialData = action.payload;
      //   state.loading = false;
    },
  },
});

// export const { setUserList, setLogout, setCurrentUser, imageUserRmove } =
//   usersSlice.actions;
export const { setCurrentTrial, setSchedule, setVariable } = trialSlice.actions;
export default trialSlice.reducer;
