import React from "react";
import { useEffect } from "react";
import { useState } from "react";

function Pagination({
  totalPage,
  defaultActivePage,
  NextIcon,
  onhandlePageChange,
  previousIcon,
  nextIcon,
}) {
  let pageItem;
  let isLeftBreak = true;
  let isRightBreak = true;

  if (!totalPage) totalPage = 1;
  if (totalPage <= 7)
    pageItem = new Array(totalPage || 1).fill(0).map((item, i) => i + 1);
  else {
    //find Active Around Page..
    //find left break Point
    //and left part of number
    //find the right break Point
    //right part of number
    //merge the array

    let activePart = [];
    let firstPartV2 = [];
    let lastPartV2 = [];
    if (defaultActivePage == 1) {
      isLeftBreak = false;
      isRightBreak = true;
      firstPartV2 = [];
      activePart = [1, 2, 3];
      lastPartV2 = [totalPage];
    } else if (defaultActivePage <= 4) {
      isLeftBreak = false;
      isRightBreak = true;
      // firstPartV2 = [];
      // activePart = [1, 2, 3, 4];

      firstPartV2 = new Array(defaultActivePage - 2)
        .fill(0)
        .map((item, i) => i + 1);
      activePart = [
        defaultActivePage - 1,
        defaultActivePage,
        defaultActivePage + 1,
        // defaultActivePage + 2,
      ];
      lastPartV2 = [totalPage];
    } else if (totalPage > 4 && totalPage - defaultActivePage <= 3) {
      isRightBreak = false;
      lastPartV2 = [];


      if (totalPage > defaultActivePage) {
        lastPartV2 = new Array(totalPage - defaultActivePage)
          .fill(0)
          .map((item, index) => totalPage - index)
          .reverse();

      }
      activePart = [
        defaultActivePage - 2,
        defaultActivePage - 1,
        defaultActivePage,
      ];
      firstPartV2 = [1];
    } else {
      isLeftBreak = true;
      isRightBreak = true;
      activePart = [
        defaultActivePage - 1,
        defaultActivePage,
        defaultActivePage + 1,
      ];
      firstPartV2 = [1];
      lastPartV2 = [totalPage];
    }

    // isLeftBreak = defaultActivePage < 4 ? false : true;
    // isRightBreak = totalPage - defaultActivePage < 4 ? false : true;

    if (isLeftBreak) firstPartV2.push("...");
    if (isRightBreak) lastPartV2 = ["...", ...lastPartV2];

    pageItem = [...firstPartV2, , ...activePart, ...lastPartV2];


    // let firstPart = new Array(Math.round(totalPage / 2))
    //   .fill(0)
    //   .map((item, i) => i + 1);
    // let breakPart = "...";
    // let lastPart = new Array(Math.round(totalPage / 2 - 1))
    //   .fill(0)
    //   .map((item, i) => firstPart[firstPart.length - 1] + i + 1);
    // // pageItem = firstPart + breakPart + lastPart;
    // // pageItem = [...firstPart, breakPart, ...lastPart];
  }
  // pageItem = new Array(totalPage || 1).fill(0).map((item, i) => i + 1);

  useEffect(() => {
  }, []);

  const handleOnClick = (page) => {
    onhandlePageChange(page);
  };

  return (
    <div className="pagination-container">
      <div
        className="pagination-item pagination-icon"
        onClick={
          defaultActivePage !== 1
            ? () => handleOnClick(defaultActivePage - 1)
            : () => { }
        }
      >
        <img src={previousIcon} alt="previous" />
      </div>
      {pageItem.map((item, index) => {
        return (
          <div
            className={`pagination-item " ${defaultActivePage === item ? "active-item" : ""
              }`}
            value={item}
            onClick={(e) => {
              if (item === "..." && isLeftBreak)
                return handleOnClick(defaultActivePage - 3);
              if (item === "..." && isRightBreak) {
                return handleOnClick(defaultActivePage + 3);
              }

              handleOnClick(item);
            }}
          >
            {item}
          </div>
        );
      })}
      <div
        className="pagination-item pagination-icon"
        onClick={
          defaultActivePage !== totalPage
            ? () => handleOnClick(defaultActivePage + 1)
            : () => { }
        }
      >
        <img src={nextIcon} alt="next" />
      </div>
    </div>
  );
}

export default Pagination;
