import axios from "axios";
import { encrypt, decrypt, showErrorToast, showSuccessToast } from "./common";
import { config } from "../constants/config";
// ${URL.imageUrl}/api/v1/admin
// http://5331-2405-201-2029-a83c-cc8a-89d1-700c-419.ngrok.io
// https://nof1.app/backend/api/v1/admin
//http://localhost:3267/api/v1/admin
//dev server: http://54.201.160.69:3267/

// axios.defaults.baseURL = api.API_URL;
// // content type
// axios.defaults.headers.post["/v1/adminContent-Type"] = "application/json";
// // content type
// const token = JSON.parse(sessionStorage.getIt/v1/adminem("authUser"))
//   ? JSON.parse(sessionStorage.getIt/v1/adminem("authUser")).token
//   : null;
// if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;

// intercepting to capture errors

//comment
const API = axios.create({
  // baseURL: `http://54.201.160.69:3267/api`,
  baseURL: config.baseURL,
  timeout: 10 * 1000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("token")).token
      }`;
  }
  return req;
});

API.interceptors.response.use(async function (response) {
  // const history = useHistory();
  let data = await decrypt(response.data.data);

  switch (data.code) {
    case 403:
      localStorage.removeItem("token");
      // alert(data.message);
      // showSuccessToast("Login Session is expired. Please Login Again");
      showErrorToast(
        data.message || "Login Session is expired. Please login Again."
      );
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);

      break;
    default:
      return response;
  }
  return response;
});

const header = () => {
  let token = JSON.parse(localStorage.getItem("token")).token;
  let headerContent = {
    headers: {
      Authorization: `Bearer ${token}`,
      userType: "admin",
    },
  };
  return headerContent;
};

const encrytedBody = async (formData) => {
  const data = formData;
  const encrypted = await encrypt(JSON.stringify(formData));
  formData = { data: encrypted };
  console.log({ formData })
  return formData;
};

export const signIn = async (formData) =>
  API.post("/v1/admin/auth/signin", await encrytedBody(formData));

/**Manage Users */
export const userManagment = async (page, search, type) =>
  API.get(
    `/v1/admin/user-manage/users?page=${page}&limit=10&userType=${type}&search=${search}`,
    header()
  );
export const userAddManagment = async (value, type) =>
  API.post(`/v1/admin/user-manage/users`, await encrytedBody(value), header());
export const users = async (data) =>
  API.put(`/v1/admin/user-manage/users`, { ...data }, header());
export const usersPro = async (data) =>
  API.post(`/v1/admin/user-manage/users/pro`, { ...data }, header());
export const userDelete = async (id) =>
  API.delete(`/v1/admin/user-manage/users/${id}`, header());
export const userEdit = async (id) =>
  API.get(`/v1/admin/user-manage/users/${id}`, header());
export const userEditSubmit = async (id, values) =>
  API.put(
    `/v1/admin/user-manage/users/${id}`,
    await encrytedBody(values),
    header()
  );
/**Manage Category */
export const category = async (page, search, limit) =>
  API.get(
    `/v1/admin/category?page=${page}&limit=${limit}&search=${search}`,
    header()
  );

export const categoryDelete = async (id) =>
  API.delete(`/v1/admin/category/${id}`, header());
export const categoryManagament = async (value) =>
  API.post("/v1/admin/category", await encrytedBody(value), header());
export const categoryEdit = async (id) =>
  API.get(`/v1/admin/category/${id}`, header());
export const categoryEditSubmit = async (id, values) =>
  API.put(`/v1/admin/category/${id}`, await encrytedBody(values), header());
export const categoryForAdmin = async () =>
  API.get(`/v1/admin/category?filter=admin&page=1&limit=10000000`, header());

// manege trial
// {{localUrl}}/v1/admin/user-manage/users/:userId/trials
export const trialManagment = async (id) =>
  API.get(`/v1/admin/user-manage/users/${id}/trials`, header());
export const getTrialById = async (userId, trialId) =>
  API.get(`/v2/admin/user-manage/users/${userId}/trials/${trialId}`, header());
/**Manage Goals API */
export const goal = async (page, search) =>
  API.get(`/v1/admin/goal?page=${page}&limit=${10}&search=${search}`, header());
export const goalDelete = async (id) =>
  API.delete(`/v1/admin/goal/${id}`, header());
export const goalManagament = async (value) =>
  API.post("/v1/admin/goal", await encrytedBody(value), header());
export const goalEditSubmit = async (id, values) =>
  API.put(`/v1/admin/goal/${id}`, await encrytedBody(values), header());
export const goalEdit = async (id) => API.get(`/v1/admin/goal/${id}`, header());
export const goalForAdmin = async (categoryId) =>
  API.get(
    `/v1/admin/goal?filter=admin&categoryId=${categoryId}&page=1&limit=10000000`,
    header()
  );
/**Manage Intervention API */
export const intervension = async (page, search) =>
  API.get(
    `/v1/admin/intervention?page=${page}&limit=${10}&search=${search}`,
    header()
  );

export const interventionForAdmin = async (categoryId) =>
  API.get(
    `/v1/admin/intervention?filter=admin&categoryId=${categoryId}&page=1&limit=10000000`,
    header()
  );
export const intervensionDelete = async (id) =>
  API.delete(`/v1/admin/intervention/${id}`, header());
export const intervensionManagament = async (value) =>
  API.post("/v1/admin/intervention", await encrytedBody(value), header());
export const intervensionEditSubmit = async (id, values) =>
  API.put(`/v1/admin/intervention/${id}`, await encrytedBody(values), header());
export const intervensionEdit = async (id) =>
  API.get(`/v1/admin/intervention/${id}`, header());

/**Manage Variable */
export const variable = async (page, limit, search, filter) =>
  API.get(
    `/v1/admin/variable?page=${page}&limit=${limit}&search=${search}&filter=${filter}`,
    header()
  );
export const variableManagament = async (value) =>
  API.post("/v1/admin/variable", await encrytedBody(value), header());
export const variableEditSubmit = async (id, values) =>
  API.put(`/v1/admin/variable/${id}`, await encrytedBody(values), header());
export const variableDelete = async (id) =>
  API.delete(`/v1/admin/variable/${id}`, header());

export const variableForAdmin = async (categoryId) =>
  API.get(
    `/v1/admin/variable?trialFilter=admin&page=1&limit=10000000`,
    header()
  );

//**Dashboard */
export const dashbaordOverview = async () =>
  API.get(`/v1/admin/dashboard/overview`, header());
// /v1/admin/dashboard/overview

/* Report API */
export const reportList = async (status, page, limit) =>
  API.get(
    `/v2/admin/report?page=${page}&limit=${limit}&status=${status}`,
    header()
  );
export const reportById = async (reportId) =>
  API.get(`/v2/admin/report/${reportId}`, header());
export const actionOnReportContent = async (reportId, values) =>
  API.put(`/v2/admin/report/${reportId}`, await encrytedBody(values), header());

//admin trial
export const addTrialFromAdmin = async (value) =>
  API.post("/v2/admin/trial", await encrytedBody(value), header());

export const getAdminTrials = async (page, limit) =>
  API.get(`/v2/admin/trial?page=${page}&limit=${limit}`, header());

export const getTrials = async (page, limit, search, status) =>
  API.get(`/v2/admin/trial/user-trial?page=${page}&limit=${limit}&type=${status}&search=${search}`, header());

export const deleteTrial = async (trialId) =>
  API.post(`/v2/admin/trial/user-trial/delete`, await encrytedBody(trialId), header());
export const exportTrial = async (trialId) =>
  API.get(`/v2/admin/trial/export/${trialId}`, header());
