import { useNavigate } from "react-router-dom"

const { Box, Button } = require("@mui/material")

const NotFound = () => {
    const navgation = useNavigate()

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "800px",
                    alignContent: "center",
                    display: "flex",
                    justifyContent: "center",
                    justifyItems: "center",
                    margin: "0 auto",
                }}
            >
                <img
                    style={{ width: "100%", alignItems: "center" }}
                    src={require("../../assets/img/404.png")}
                    alt=""
                />
            </Box>
            <Box
                justifyContent={"center"}
                paddingTop={"10rem"}
                className="d-flex justify-content-center searchBox"
            >
                <Button
                    variant="contained"
                    className="add_btn"
                    onClick={() => {
                        navgation("/")
                    }}
                >
                    Go to Home
                </Button>
            </Box>
        </>
    )
}

export default NotFound