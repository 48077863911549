import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
// import { ToastContainer } from "react-toastify/dist";
import Header from "../../components/common/Header";
import SideBar from "../../components/common/SideBar";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import ReportList from "../../components/dashboard/report/ReportList";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useDispatch } from "react-redux";
import { reportList } from "../../slice/reportSlice";
const Report = () => {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      reportList({
        page: 1,
        limit: 10,
        status: "pending",
      })
    );
  }, []);
  return (
    <>
      <div className="dashboard-wrapper d-flex flex-column flex-fill overflow-auto">
        <Header setToggle={setToggle} toggle={toggle} />
        <div className="inner-wrapper">
          <div
            className={`left-col d-flex flex-column  ${
              toggle ? "collapseSidebar" : ""
            } `}
          >
            <SideBar setToggle={setToggle} toggle={toggle}></SideBar>
          </div>

          <div
            className={`right-col d-flex flex-column ${
              toggle ? "expandDashboard" : ""
            }`}
          >
            <Typography variant="h4" class="page_title">
              Reports
            </Typography>
            <Tabs>
              <TabList>
                <Tab>Open Reports</Tab>
                <Tab>Closed Reports</Tab>
              </TabList>

              <TabPanel>
                <ReportList status={"pending"} />
              </TabPanel>
              <TabPanel>
                <ReportList status={"resolved"} />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      {/* <EditVariable
        key={isEditOpen}
        // onEditPerform={(res) => performAction(res)}
        handleClose={() => {
          setEditOpen(false);
          dispatch(setCurrentVariable({}));
        }}
        open={isEditOpen}
        // id={id}
      /> */}
      <ToastContainer />
    </>
  );
};

export default Report;
