import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
 
const ConfirmModel = ({
  open,
  handleClose,
  handleClick,
  message,
  secondMessage = "",
  okText = "Okay",
  cancleText = "Cancel",
  ...props
}) => {
  return (
    <Dialog open={open}>
      {/* <DialogTitle variant="body">
                Confirm Logut?
            </DialogTitle> */}
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            minWidth: "450px",
            minHeight: "70px",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            className="border-top border-info border-3 bg-white mt-3 p-2 "
            style={{ width: "100%", fontSize: "large" }}
          >
            <p>
              {message}
              {secondMessage && (
                <>
                  <br />
                  {secondMessage}
                </>
              )}
            </p>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose("close")}>{cancleText}</Button>
        <Button
          variant="contained"
          type="button"
          onClick={() => handleClick(props)}
        >
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModel;
