import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setVariable } from "../../../slice/adminTrialSlice";
import { getAdminInterventionList } from "../../../slice/interventionSlice";
import FitbitIcon from "@mui/icons-material/Fitbit";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import adminB from "../../../assets/img/admin_b.svg";
import PersonIcon from "@mui/icons-material/Person";
import oura from "../../../assets/img/oura.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { hhMMTotimeStamp, timeStampToHHMMstring } from "../../../helpers/date";
import { getAdminVariableList } from "../../../slice/variableSlice";
import moment from "moment";
import { showErrorToast } from "../../../helpers/common";

// import { setSchedule } from "../../../slice/trialSlice";

const VariableDialogue = (props) => {
  const dispatch = useDispatch();

  let { open, handleClose, index, handleVariableIndex } = props;
  const [selectedVariable, setSelectedVariable] = useState(null);
  const [schedule, setSchedule] = useState([]);
  const [filteredList, setFilteredList] = useState([])

  const { admin_current_trial, variableList } = useSelector((state) => ({
    admin_current_trial: state.admin_trial.admin_current_trial,
    variableList: state.variable?.all_variable?.list || [],
  }));

  useEffect(() => {
    // dispatch(getAdminVariableList({}));
    return () => {
      setSchedule([]);
      setSelectedVariable(null);
    };
  }, []);

  useEffect(() => {
    if (index != undefined) {
      setSelectedVariable(admin_current_trial.variables[index]);
      setSchedule(admin_current_trial.variables[index].schedule);
      setFilteredList([...variableList])

    } else {
      const newArr = variableList?.filter(o => admin_current_trial?.variables?.length > 0 ? admin_current_trial?.variables?.some(a => o.name != a.name) : o) || []
      setFilteredList(newArr)
    }
  }, [index]);

  const handleClick = (type) => {
    // dispatch(imageRmove(""));
    switch (type) {
      case "Add": {
        if (admin_current_trial.variables.find(o => o.name === selectedVariable.name)) {
          showErrorToast("This variable already exist!")
        } else {
          const scheduleArr = []
          schedule.map(o => {
            if (scheduleArr.includes(o)) {
              showErrorToast("Same schedule time can't be added")
              scheduleArr = null
              // break;
            } else {
              scheduleArr.push(o)
            }
          })
          if (scheduleArr?.length > 0) {
            if (typeof index == "number") {
              dispatch(
                setVariable({
                  index: index,
                  type: "edit",
                  variables: {
                    _id: selectedVariable._id,
                    name: selectedVariable.name,
                    schedule: schedule,
                  },
                })
              );
            } else {
              dispatch(
                setVariable({
                  type: "add",
                  variables: {
                    _id: selectedVariable._id,
                    name: selectedVariable.name,
                    schedule: schedule,
                  },
                })
              );
            }
            handleClose();
            break;
          }
        }
      }
      case "close": {
        handleClose();
        break;
      }

      default:
        break;
    }

    handleVariableIndex(undefined);
    setSchedule([])
    setSelectedVariable(null)
  };
  return (
    <Dialog open={open}>
      <DialogTitle variant="body">Variable</DialogTitle>
      <DialogContent>
        <>
          <div>
            <select
              style={{ width: "60%", minWidth: "400px", maxWidth: "450px" }}
              className="form-control"
              name=""
              id=""
              value={selectedVariable?._id}
              onChange={(e) => {
                let variable = filteredList.find(
                  (vars) => vars._id == e.target.value
                );
                setSelectedVariable(variable);
              }}
            >
              <option value="">Select Variable</option>
              {filteredList &&
                filteredList.map((variable, index) => {
                  return (
                    <option key={index} value={variable._id}>
                      {" "}
                      {variable.name}{" "}({variable.from})
                      {/* {(() => {
                        switch (variable.from) {
                          case "admin":
                            return <img
                              src={adminB}
                              style={{ display: "flex", justifyContent: "center" }}
                              alt="adminIcon"
                            />
                          case "oura":
                            return <img
                              src={oura}
                              style={{ display: "flex", justifyContent: "center" }}
                              alt="adminIcon"
                            />
                          case "user":
                            return <PersonIcon style={{ color: "#00308F" }} />
                          default:
                            return <FitbitIcon style={{ color: "#75c1c2" }} />
                        }
                      })()} */}
                    </option>
                  );
                })}
            </select>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>Schedule</h2>
            <Button
              variant="contained"
              className="add_btn"
              style={{ marginTop: "20px" }}
              //   margin={"10px 0px"}
              //  color="#556B2F"
              onClick={() => {
                // boundArrayHelpers.push({
                //   label: "",
                //   value: "",
                // });
                // dispatch(setSchedule({ index: index, type: "add" }));
                setSchedule([...schedule, moment().format("HH:mm")]);
              }}
              disabled={!selectedVariable}
            >
              Add
            </Button>
          </div>
          {schedule?.map((item, i) => (
            <Box
              className={`row ${i % 2 == 0 && "bg-light"}`}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              key={i}
              paddingY={"10px"}
              borderBottom={"2px solid #ccc"}
              width={"100%"}
              gap={"5px"}
              flexWrap={"wrap"}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="schedule-row">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopTimePicker
                    label="Schedule Time"
                    value={hhMMTotimeStamp(item || moment().format("HH:mm"))}
                    ampm={false}
                    onChange={(newValue) => {
                      let value = timeStampToHHMMstring(newValue);
                      let tempSchedule = [...schedule];
                      tempSchedule.splice(i, 1, value);
                      setSchedule(tempSchedule);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                {/* <Box>
                <input
                  type="text"
                  value={item}
                  className="form-control"
                  style={{
                    marginTop: "0px",
                  }}
                  // onChange={(e) =>
                  //   dispatch(
                  //     setSchedule({
                  //       index: index,
                  //       type: "edite",
                  //       dIndex: i,
                  //       value: e.target.value,
                  //     })
                  //   )
                  // }
                  placeholder="Enter Schedule"
                  onChange={(e) => {
                    let value = e.target.value;
                    let tempSchedule = [...schedule];
                    tempSchedule.splice(i, 1, value);
                    setSchedule(tempSchedule);
                  }}
                />
              </Box> */}
                <Box>
                  <i
                    class="fa-solid fa-trash trash-btn"
                    onClick={() => {
                      let tmpSchedule = [...schedule];
                      tmpSchedule.splice(i, 1);
                      setSchedule(tmpSchedule);
                    }}
                  ></i>
                </Box>
              </div>
            </Box>
          ))}
        </>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleClick("close")}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleClick("Add")}
          disabled={!selectedVariable || !schedule.length}
        >
          Complete
        </Button>
        {/* <Button
              variant="contained"
              type="submit"
              // onClick={() => handleClick("save")}
            >
              Save
            </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default VariableDialogue;
