import Avatar from "@mui/material/Avatar";
import Applogo from "../../assets/img/logo.png";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { showSuccessToast, showErrorToast } from "../../helpers/common";
import GLOBALS from "../../constants";
import LogutModel from "./LogOut";
const { MESSAGES } = GLOBALS;

export default function Header({ setToggle, toggle }) {
  function toggleSidebar() {
    setToggle(!toggle);
  }
  const navigate = useNavigate();
  const [admin_name, setAdminName] = useState("");
  const [show, setShow] = useState(false);
  const adminContent = localStorage.getItem("token");
  let admin_data = {};

  if (!adminContent || adminContent == "undefined") {
    showErrorToast("Sign Out! Please Login Again. Navigating to Login Page");
    localStorage.removeItem("token");
    setTimeout(() => {
      navigate("/");
    }, 1000);
    //   admin_data = JSON.parse(adminContent);
  }

  admin_data = JSON.parse(adminContent);

  useEffect(() => {
    if (admin_data) {
      setAdminName(admin_data.name);
    }
  }, []);

  const handleOpen = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const onLogout = () => {
    showSuccessToast(MESSAGES.LOGOUT);
    setTimeout(() => {
      localStorage.clear();
      navigate("/");
    }, 1000);
  };
  return (
    <>
      <header className="dashboard-header d-flex align-items-center">
        <div className="left-col">
          <div className="logoBox d-flex align-items-center">
            <div className="trigger" onClick={toggleSidebar}>
              <i class="fa-solid fa-bars"></i>
            </div>
            <img
              className="headerLogo"
              src={require("../../assets/img/logo-white.png")}
              alt="screenShot"
            />
          </div>
        </div>
        <div className="right-col ms-auto">
          <div className="user-profile-cell d-inline-flex align-items-center">
            <span style={{ color: "#FFF", fontSize: "20px" }}>{admin_name}</span>
            <IconButton
              aria-label="delete"
              color="secondary"
              onClick={() => {
                handleOpen();
              }}
            >
              <LogoutIcon />
            </IconButton>
          </div>
        </div>
      </header>
      <LogutModel open={show} handleClose={handleClose} handleClick={onLogout} />
    </>
  );
}
