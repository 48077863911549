import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { styled } from "@mui/material/styles";
import frtSvg from "../../../assets/frontArrow.svg";
import bckSvg from "../../../assets/backArrow.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Box,
} from "@mui/material";
import Table from "@mui/material/Table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../common/DeleteModal";
// import SideBar from "../../components/common/SideBar";
import SideBar from "../../common/SideBar";
import Header from "../../common/Header";
import {
  trialData,
  setCurrentTrial,
  setCurrentTrialOfUser,
} from "../../../slice/trialSlice";
import moment from "moment/moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { exportTrialOfUser } from "../../../slice/adminTrialSlice";
import { config } from "../../../constants/config";
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function UserTrialList(props) {
  const { id } = useParams();
  const pageCount = 2;
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const router = useParams();
  const { data } = useSelector((store) => ({
    data: store.trial.user_all_trial,
  }));
  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);

  useEffect(() => {
    dispatch(trialData({ id: id }));
  }, []);
  const handleExport = async (trialId) => {
    const result = await exportTrialOfUser(trialId)
    if (result) {
      window.open(`${config.imageUrl}/${result.path}`, "_blank")
    } else {
      toast.error("Something went wrong!")
    }
  }

  return (
    <div className="dashboard-wrapper d-flex flex-column flex-fill overflow-auto">
      <Header setToggle={setToggle} toggle={toggle} />
      <div className="inner-wrapper">
        <div
          className={`left-col d-flex flex-column  ${toggle ? "collapseSidebar" : ""
            } `}
        >
          <SideBar setToggle={setToggle} toggle={toggle}></SideBar>
        </div>
        <div
          className={`right-col d-flex flex-column ${toggle ? "expandDashboard" : ""
            }`}
        >
          <Box
            sx={{
              textAlign: "start",
              marginBottom: "0.9em",
              display: "flex",
              alignItems: "center",
              marginTop: "3em",
              justifyContent: "flex-start",
              cursor: "pointer",
              width: "20%",
            }}
            onClick={(e) => {
              navigate(-1);
            }}
          >
            <ArrowBackIosIcon />
            <Box component="span" sx={{ fontSize: "25px" }}>
              Back
            </Box>
          </Box>
          <Typography variant="h4" class="page_title">
            {data?.userInfo?.name}'s Created Trial List
          </Typography>
          {/* <Box */}
          {/* <div> */}
          {/* userTrialList */}
          <>
            <Grid item xs={6} sm={6} md={9}>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow sx={{ color: "red" }}>
                      <StyledTableCell align="left">Sr. No</StyledTableCell>
                      <StyledTableCell align="left">Name</StyledTableCell>
                      <StyledTableCell align="left">Idea</StyledTableCell>
                      {/* <StyledTableCell align="left">Actions</StyledTableCell> */}
                      <StyledTableCell align="left">Category</StyledTableCell>
                      <StyledTableCell align="left">Status</StyledTableCell>
                      <StyledTableCell align="left">Actions</StyledTableCell>

                      {/* <StyledTableCell align="left">Delete</StyledTableCell> */}
                      {/* <StyledTableCell align="left">User Type</StyledTableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.list?.map((content, index) => {
                      return (
                        <StyledTableRow key={index}>
                          {/* {content} */}

                          <StyledTableCell component="th" scope="row">
                            {index + 1}
                            {/* {((props?.page || 1) - 1) * 10 + index + 1} */}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {content.name}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {content.idea}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {content?.category?.name}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {moment().isBefore(content?.startDate)
                              ? "not started yet"
                              : moment().isBetween(
                                content?.startDate,
                                content?.endDate
                                // moment(content?.startDate, "MM-DD-YYYY"),
                                // moment(content?.endDate, "MM-DD-YYYY")
                              )
                                ? "onGoing"
                                : "Ended"}
                          </StyledTableCell>
                          {/* <StyledTableCell component="th" scope="row">
                      <IconButton
                        variant="contained"
                        className="edit-icon"
                        // color="purple[500]"
                        aria-label="convertToAdmin"
                        onClick={() => handleClickOpen(user._id)}
                      >
                        <AdminPanelSettings />
                      </IconButton>
                    </StyledTableCell> */}
                          <StyledTableCell component="th" scope="row">
                            <IconButton
                              onClick={() => {
                                // onDelete(item);
                                // handleDeleteOpen(user);
                                dispatch(setCurrentTrial(content));
                                navigate(
                                  `/users/${router.id}/trial/${content.trialId}`
                                );
                              }}
                              className="trash-icon"
                              aria-label="delete"
                            >
                              <VisibilityIcon style={{ color: "#18a6d5" }} />
                            </IconButton>
                            <Button
                              onClick={() => {
                                handleExport(content.trialId)
                              }}
                              aria-label="delete"
                              variant="contained"
                            >
                              Export
                            </Button>
                          </StyledTableCell>
                          {/* <StyledTableCell component="th" scope="row">
                     
                    </StyledTableCell> */}
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {!data?.list?.length ? (
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "800px",
                    alignContent: "center",
                    display: "flex",
                    justifyContent: "center",
                    justifyItems: "center",
                    margin: "0 auto",
                  }}
                >
                  <img
                    style={{ width: "100%", alignItems: "center" }}
                    src={require("../../../assets/img/404.png")}
                    alt=""
                  />
                </Box>
              ) : (
                <></>
              )}
            </Grid>
          </>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default UserTrialList;
