import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../helpers/RestClient";
import {
  encrypt,
  decrypt,
  showSuccessToast,
  showErrorToast,
} from "../helpers/common";
import GLOBALS from "../constants";
const { MESSAGES } = GLOBALS;

export const signIn = createAsyncThunk(
  "auth/signin",
  async ({ values, navigate }, { dispatch }) => {
    try {
      const response = await api.signIn(values);

      if (response) {
        const data = response.data.data;
        const decrypt_data = await decrypt(response.data.data);
        if (decrypt_data && decrypt_data.code == 200) {
          localStorage.setItem("token", JSON.stringify(decrypt_data.result));
          dispatch(setUser(decrypt_data.result));
          navigate("/users");
        } else {
          showErrorToast("Invalid Credentials!");
          navigate("/");
        }

        // localStorage.setItem("token", JSON.stringify(data.data.result));
        //navigate("/admin");
        return data;
      }
      //   toast.success("Login Successfully");
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  user: null,
  error: "",
  loading: false,
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLogout: (state, action) => {
      localStorage.clear();
      state.user = null;
    },
  },
  extraReducers: {
    [signIn.pending]: (state, action) => {
    },
    [signIn.fulfilled]: (state, action) => {
    },
    [signIn.rejected]: (state, action) => {
    },
  },
});

export const { setUser, setLogout } = authSlice.actions;
export default authSlice.reducer;
