import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../helpers/RestClient";
import * as apiImage from "../helpers/ImageCommon";

import { encrypt, decrypt, showSuccessToast } from "../helpers/common";
import GLOBALS from "../constants";

const { MESSAGES } = GLOBALS;

export const userData = createAsyncThunk(
  "user-manage/users",
  async ({ page, search, type }, { dispatch }) => {
    try {
      const response = await api.userManagment(page, search, type);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        // dispatch(setUserList(decrypt_data.result));
        return decrypt_data.result;
      }
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

export const addUserData = createAsyncThunk(
  "/user/add",
  async ({ values, dispatch, type }, { rejectWithValue }) => {
    try {
      const response = await api.userAddManagment(values, type);
      if (response) {
        const data = await encrypt(response.data.data);
        // if (data.data.code == 200) {
        //   navigate("/admin/goal");
        // }
        dispatch(userData({ page: 1, search: "", type: "user" }));
        showSuccessToast(MESSAGES.USER_DEL);

        return data;
      }
      return response;
      //   toast.success("Login Successfully");
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadImage = createAsyncThunk(
  "/image",
  async (values, { dispatch }) => {
    try {
      const response = await apiImage.imageUpload(values);
      if (response) {
        // const data = await encrytedBody(response.data.data);
        // if (data.data.code == 200) {
        //   // navigate("/admin/category");
        // }
        dispatch(imageUserRmove(response?.data?.result));
        // return response?.data?.result;
      }
      // return response;
      //   toast.success("Login Successfully");
    } catch (err) {
      // return rejectWithValue(err.response.data);
    }
  }
);

export const editUser = createAsyncThunk(
  "editUser",
  async ({ id, values, dispatch }, { rejectWithValue }) => {
    try {
      const response = await api.userEditSubmit(id, values);
      if (response) {
        const data = await decrypt(response.data.data);
        // navigate("/admin/category");
        dispatch(userData({ page: 1, search: "", type: "user" }));
        dispatch(setCurrentUser({}));
        showSuccessToast(MESSAGES.USER_EDIT);

        return data;
      }
      return response;
      //   toast.success("Login Successfully");
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteUser = createAsyncThunk(
  "/UserDelete",
  async (data, { dispatch, getState }) => {
    try {
      const response = await api.userDelete(data.data._id);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(userData({ page: 1, search: "", type: "user" }));
        dispatch(setCurrentUser({}));
        showSuccessToast(MESSAGES.USER_DEL);
      }
    } catch (err) {}
  }
);
// export const editUser =
export const usersConvert = createAsyncThunk(
  "/user-manage/users",
  async (data, { dispatch }) => {
    try {
      const response = await api.users(data);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(
          userData({
            page: 1,
            search: "",
            type: data.userType == "admin" ? "user" : "admin",
          })
        );
      }
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

export const usersPro = createAsyncThunk(
  "/user-manage/users/pro",
  async ({ userId, isPro, page, type, search }, { dispatch }) => {
    try {
      const response = await api.usersPro({ userId, isPro });
      const decrypt_data = await decrypt(response.data.data);
      console.log({ decrypt_data });
      if (decrypt_data.code == 200) {
        dispatch(
          userData({
            page: page,
            search: search,
            type: type,
          })
        );
      }
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  user: null,
  error: "",
  loading: false,
};
const usersSlice = createSlice({
  name: "userManagment",
  initialState: {
    all_users: null,
    error: "",
    loading: false,
    image: "",
    current_user: {},
    trial_list: [],
    current_trial: {},
  },
  reducers: {
    setUserList: (state, action) => {
      state.all_users = action.payload;
    },
    setLogout: (state, action) => {
      localStorage.clear();
      state.user = null;
    },
    setCurrentUser: (state, action) => {
      state.current_user = action.payload;
    },
    imageUserRmove: (state, action) => {
      state.image = action.payload;
    },
  },
  extraReducers: {
    [userData.pending]: (state, action) => {
      state.loading = true;
    },
    [userData.fulfilled]: (state, action) => {
      state.all_users = action.payload;
      state.loading = false;
    },
    [userData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export const {
  setUserList,
  setLogout,
  setCurrentUser,
  imageUserRmove,
} = usersSlice.actions;
export default usersSlice.reducer;
