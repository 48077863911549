import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as api from "../helpers/RestClient";
import { encrypt, decrypt, showSuccessToast } from "../helpers/common";
import GLOBALS from "../constants";
const { MESSAGES } = GLOBALS;
// import { decrypt, encrypt } from "../hippa";
// action
const encrytedBody = async (response) => {
  const data = response;
  const encrypted = await decrypt(response);
  response = { data: encrypted };

  return response;
};

export const variableList = createAsyncThunk(
  "/variable",
  async ({ page, limit, search, filter }, { rejectWithValue }) => {
    try {
      // alert("hello");
      const response = await api.variable(page, limit, search, filter);
      if (response) {
        const data = await encrytedBody(response.data.data);

        return data.data.result;
      }
      //   return response;
      //   toast.success("Login Successfully");
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteVariable = createAsyncThunk(
  "/variableDelete",
  async (data, { dispatch, getState }) => {
    try {
      const response = await api.variableDelete(data.id);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(variableList({ page: 1, limit: 10, search: "" }));
        dispatch(setCurrentVariable({}));
        showSuccessToast(MESSAGES.VARIABLE_DEL);
      }
    } catch (err) {}
  }
);
// export const category = createAsyncThunk(
//   "/category",
//   async (value, { rejectWithValue }) => {
//     try {
//       const response = await api.category();
//       if (response) {
//         const data = await encrytedBody(response.data.data);
//         return data;
//       }
//       //   return response;
//       //   toast.success("Login Successfully");
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

export const editVariable = createAsyncThunk(
  "editVariable",
  async ({ id, values, dispatch }, { rejectWithValue }) => {
    try {
      const response = await api.variableEditSubmit(id, values);
      if (response) {
        const data = await encrytedBody(response.data.data);
        // navigate("/admin/category");
        dispatch(variableList({ page: 1, limit: 10, search: "" }));
        dispatch(setCurrentVariable({}));
        showSuccessToast(MESSAGES.VARIABLE_EDIT);

        return data;
      }
      return response;
      //   toast.success("Login Successfully");
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const addVariable = createAsyncThunk(
  "/variable/add",
  async ({ values, dispatch }, { rejectWithValue }) => {
    try {
      const response = await api.variableManagament(values);
      if (response) {
        const data = await encrytedBody(response.data.data);
        // if (data.data.code == 200) {
        //   navigate("/admin/goal");
        // }
        dispatch(variableList({ page: 1, limit: 10, search: "" }));
        showSuccessToast(MESSAGES.VARIABLE_ADD);

        return data;
      }
      return response;
      //   toast.success("Login Successfully");
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAdminVariableList = createAsyncThunk(
  "/variable/admin",
  async ({}, { dispatch }) => {
    try {
      const response = await api.variableForAdmin();
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        return decrypt_data.result;
      }
      return;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

// export const goalDelete = createAsyncThunk(
//   "goal/:id",
//   async (id, { rejectWithValue }) => {
//     // const dispatch = useDispatch();
//     try {
//       const response = await api.goalDelete(id);
//       if (response) {
//         // dispatch(goal(""));
//         const data = await encrytedBody(response.data.data);
//         if (data) {
//           toast.success(data.data.message || "SUCCESS!", { autoClose: 3000 });
//         }
//         return data;
//       }
//       //   return response;
//       //   toast.success("Login Successfully");
//     } catch (err) {
//       toast.error("something went worng", { autoClose: 3000 });
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

// export const goalEditSubmit = createAsyncThunk(
//   "/goal/goalmanagment/:id",
//   async ({ id, values, navigate }, { rejectWithValue }) => {
//     try {
//       const response = await api.goalEditSubmit(id, values);
//       if (response) {
//         const data = await encrytedBody(response.data.data);
//         if (data) {
//           navigate("/admin/goal");
//         }
//         return data;
//       }
//       return response;
//       //   toast.success("Login Successfully");
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

const variableSlice = createSlice({
  name: "variable",
  initialState: {
    all_variable: [],
    error: "",
    loading: false,
    current_variable: {},

    // category: null,
    // goalData: null,
  },
  reducers: {
    setCurrentVariable: (state, action) => {
      state.current_variable = action.payload;
    },
    // setLogout: (state, action) => {
    //   localStorage.clear();
    //   state.user = null;
    // },
  },
  extraReducers: {
    [variableList.fulfilled]: (state, action) => {
      state.all_variable = action.payload;
    },

    [getAdminVariableList.fulfilled]: (state, action) => {
      state.all_variable = action.payload;
    },
  },
});

export const { setCurrentVariable } = variableSlice.actions;

export default variableSlice.reducer; //this is the default AuthReducer
