import * as Yup from "yup";

export const CategorySchema = Yup.object().shape({
  name: Yup.string().required("Name is required "),
});

export const GoalSchema = Yup.object().shape({
  name: Yup.string().required("Name is required ").matches("[a-zA-Z]", "Please enter valid Goal"),
  categoryId: Yup.string().required("Category is required "),
});

export const InterventionSchema = Yup.object().shape({
  name: Yup.string().required("Name is required "),
  categoryId: Yup.string().required("Category is required "),
});
export const VariableSchema = Yup.object().shape({
  name: Yup.string().required("Name is required "),
  inputType: Yup.string().required("inputType is required "),
  maxLabel: Yup.string().when("inputType", {
    is: (value) => value && value == "slider",
    then: Yup.string().required("Max Label is required"),
  }),
  minValue: Yup.string().when("inputType", {
    is: (value) => value && value == "slider",
    then: Yup.string().required("Min Value is required"),
  }),
  maxValue: Yup.string().when("inputType", {
    is: (value) => value && value == "slider",
    then: Yup.string().required("Max Value is required"),
  }),
  minLabel: Yup.string().when("inputType", {
    is: (value) => value && value == "slider",
    then: Yup.string().required("Min Label is required"),
  }),
  unitType: Yup.string().when("inputType", {
    is: (value) => value && value == "input",
    then: Yup.string().required("Unit Type is required"),
  }),
  unit: Yup.string().when("inputType", {
    is: (value) => value && value == "input",
    then: Yup.string().required("Unit is required"),
  }),
  options: Yup.array().when("inputType", {
    is: (value) => value && value == "list",
    then: Yup.array().of(
      Yup.object({
        label: Yup.string().required("label is required"),
        value: Yup.string().required("value is required"),
      })
    ),
  }),
});
