import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  InputLabel,
  TextField,
  Container,
  Box,
  NativeSelect,
  DesktopDatePicker,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  IconButton,
  Grid,
} from "@mui/material";
import { display } from "@mui/system";
// import dayjs from "dayjs";
import SideBar from "../../common/SideBar";
import Header from "../../common/Header";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import EditIcon from "@mui/icons-material/Edit";
import EditTrialIntervention from "./EditTrialIntervention";
import EditTrialVariables from "./EditTrialVariables";
import { categoryList } from "../../../slice/categorySlice";
import { variableList } from "../../../slice/variableSlice";
import { setCurrentTrialOfUser } from "../../../slice/trialSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import GLOBALS from "../../../constants";

const { URL } = GLOBALS;
// import { set } from "immer/dist/internal";
function Trial() {
  const [toggle, setToggle] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [index, setIndex] = useState("");
  const [VIndex, setVIndex] = useState("");
  const [isVOpen, setIsVOpen] = useState(false);
  const [boxSeries, setBoxSeries] = useState([]);
  const [lineSeries, setLineSeries] = useState([]);
  const [lineOptions, setLineOptions] = useState({
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#77B6EA", "#545454"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "Average High & Low Temperature",
      align: "left",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      title: {
        text: "Month",
      },
    },
    yaxis: {
      title: {
        text: "Temperature",
      },
      min: 5,
      max: 40,
    },
    series: {
      type: "line",
      grouped: {
        enabled: true,
        groupBy: "year",
        groupInterval: 1,
        forceNiceScale: true,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  });
  const [boxPlotType, setBoxPlotType] = useState("myResultBoxPlotValueList");
  const [boxPlotVariableIndex, setboxPlotVariableIndex] = useState(0);
  const [boxPlotList, setBoxPlotList] = useState([]);
  const [currentBoxPlotVariable, setCurrentBoxPlotVariable] = useState({});
  const [categoryImage, setCategoryImage] = useState("");
  const dispatch = useDispatch();
  const router = useParams();
  const navigate = useNavigate();
  window.dispatchEvent(new Event("resize"));

  const { current_trial, categoryData, variableData } = useSelector(
    (state) => ({
      current_trial: state.trial.trialData,
      categoryData: state.category.all_categories,
      variableData: state.variable.all_variable,
    })
  );

  const optinsBox = {
    options: {
      chart: {
        type: "boxPlot",
        height: 350,
      },
      title: {
        text: "Basic BoxPlot Chart",
        align: "left",
      },
      plotOptions: {
        boxPlot: {
          colors: {
            upper: "#5C4742",
            lower: "#A5978B",
          },
        },
      },
    },
  };

  const handleChangeImage = (name) => {
    categoryData.list.forEach((element, index) => {
      if (element.name == name) {
        setCategoryImage(element.avatar);
      }
    });
  };

  useEffect(() => {
    dispatch(
      setCurrentTrialOfUser({ userId: router.userId, trialId: router.id })
    );
    dispatch(categoryList({ page: 1, limit: 500000000000, search: "" }));
    dispatch(variableList({ page: 1, limit: 500000000000000, search: "" }));
    if (categoryData.list) {
      categoryData.list.forEach((element, index) => {
        if (element.name == current_trial?.category?.name) {
          setCategoryImage(element.avatar);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (current_trial?.graphList.length) {
      // setLineSeries(
      //   current_trial?.graphList?.[0]?.userGraphList?.map((item, index) => {
      //     return {
      //       name: item?.variableInfo?.name,
      //       data: item?.list?.map((i) => i.value),
      //     };
      //   })
      // );
      // let listOfAllValue = current_trial?.graphList?.[0]?.userGraphList
      //   .map((item) => item.list.map((i) => i.value))
      //   .flat(1);

      // setLineOptions({
      //   ...lineOptions,
      //   xaxis: {
      //     categories: current_trial?.graphList?.[0]?.userGraphList[0].list.map(
      //       (i) => new Date(i.date).toLocaleDateString()
      //     ),
      //     title: {
      //       text: "Time",
      //     },
      //   },
      //   yaxis: {
      //     title: {
      //       text: "Variables",
      //     },
      //     min: Math.min(...listOfAllValue),
      //     max: Math.max(...listOfAllValue),
      //   },
      //   title: {
      //     text: current_trial?.graphList?.[0]?.userGraphList
      //       .map((a) => a.variableInfo.name)
      //       .join(" && "),
      //     align: "left",
      //   },
      // });
      setCurrentBoxPlotVariable(current_trial.boxPlotValueList[0]);
    }
  }, [current_trial]);
  useEffect(() => {
    if (current_trial && boxPlotType) {
      setCurrentBoxPlotVariable(
        current_trial[boxPlotType][boxPlotVariableIndex]
      );
    }
  }, [boxPlotType, boxPlotVariableIndex]);
  useEffect(() => {
    if (currentBoxPlotVariable && Object.keys(currentBoxPlotVariable).length) {
      setBoxSeries([
        {
          type: "boxPlot",
          data: currentBoxPlotVariable.boxPlotValue.map((item, index) => {
            return {
              x: item.tag,
              y: item.boxPlot,
            };
          }),
        },
      ]);
    }
  }, [currentBoxPlotVariable]);

  return (
    <div className="dashboard-wrapper d-flex flex-column flex-fill overflow-auto">
      <Header setToggle={setToggle} toggle={toggle} />
      <div className="inner-wrapper">
        <div
          className={`left-col d-flex flex-column  ${toggle ? "collapseSidebar" : ""
            } `}
        >
          <SideBar setToggle={setToggle} toggle={toggle}></SideBar>
        </div>
        <div
          className={`right-col d-flex flex-column ${toggle ? "expandDashboard" : ""
            }`}
        >
          <Container
            maxWidth="md"
            style={{ textAlign: "center" }}
            className="trialcontainer"
          >
            <Box
              sx={{
                textAlign: "start",
                marginBottom: "0.9em",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                cursor: "pointer",
                width: "20%",
              }}
              onClick={(e) => {
                navigate(-1);
              }}
            >
              <ArrowBackIosIcon />
              <Box component="span" sx={{ fontSize: "25px" }}>
                Back
              </Box>
            </Box>
            <div className="userInfo">
              <img
                width="70px"
                height="50px"
                style={{ borderRadius: "50%" }}
                src={
                  current_trial?.ownerImage
                    ? `${URL.imageUrl}/${current_trial?.ownerImage}`
                    : require("../../../assets/img/profile.png")
                }
                alt=""
              />
              <span className="userTitle">
                {current_trial?.userName || "test User"}
              </span>
            </div>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: "auto", width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2}>
                <Grid xl={8} lg={8} md={8} sm={8} xs={8} item>
                  <div className="contentBox">
                    <TextField
                      label="Name"
                      disabled
                      defaultValue="Normal"
                      value={current_trial?.name}
                    />
                  </div>
                  <div className="contentBox">
                    <TextField
                      label="Idea"
                      defaultValue="Ad"
                      disabled
                      value={current_trial?.idea}
                    />
                  </div>
                </Grid>
                <Grid xl={4} lg={4} md={4} sm={4} xs={4} item>
                  <img
                    src={
                      current_trial?.image !== ""
                        ? `${URL.imageUrl}/${current_trial?.image}`
                        : require("../../../assets/img/errorImage.jpg")
                    }
                    width="100%"
                    height={"100%"}
                    alt=""
                    className="trialImage"
                  />
                </Grid>
              </Grid>
              <div className="contentBox">
                {/* <TextField
                  label="isPublic"
                  defaultValue="True"
                  value={current_trial?.isPublic}
                  fullWidth
                /> */}

                <h3>Visibility</h3>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={current_trial?.isPublic ? "Yes" : "No"}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value={'Yes'}
                    control={<Radio />}
                    label="Public"
                    disabled

                  />
                  <FormControlLabel
                    value={'No'}
                    control={<Radio />}
                    label="Private"
                    disabled
                  />
                </RadioGroup>
              </div>
              <div className="contentBox">
                <h3>Category</h3>
                <div className="categoryBox">
                  <div className="imgBox">
                    <img
                      src={
                        categoryImage
                          ? `${URL.imageUrl}/${categoryImage}`
                          : require("../../../assets/img/errorImage.jpg")
                      }
                      alt=""
                    />
                  </div>

                  <div className="TextBox">
                    <select
                      className="form-control"
                      style={{ width: "100%", padding: 10 }}
                      name="inputType"
                      disabled
                      onChange={(e) => {
                        handleChangeImage(e.target.value);
                      }}
                    // onBlur={handleBlur}
                    // value={values?.inputType}
                    >
                      <option value={current_trial?.category?.name}>
                        {current_trial?.category?.name}
                      </option>
                      {categoryData?.list?.map((item, index) => (
                        <option value={item.name} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {/* {current_trial?.category?.name} */}
                  </div>
                </div>
              </div>
              <div className="contentBox">
                <h3>Intervention</h3>
                <ul>
                  <li>
                    {current_trial?.intervention.map((intervention, index) => {
                      return (
                        <Box className="intervensionList" sx={{ my: "5px" }}>
                          <div className="tag">{intervention.tag}</div>
                          <Box sx={{ width: "60%" }} className="name">
                            <p>{intervention?.name}</p>
                          </Box>
                          <Box width={"20%"} className="name" color={"#fff"}>
                            {/* {intervention?.schedule?.map((item) => {
                              return <p>{item}</p>;
                            })} */}

                            {intervention?.schedule.join(", ")}
                          </Box>
                          <Box color={"#fff"}>
                            {current_trial?.phaseDuration} days
                          </Box>
                          <div>
                            <IconButton
                              onClick={() => {
                                // onDelete(item);
                                setIsEditOpen(true);
                                setIndex(index);
                                // navigate(`/users/trial-list/${user?._id}`);
                              }}
                              className="trash-icon"
                              aria-label="trial"
                            // disabled
                            >
                              <EditIcon />
                              {/* <TrialI /> */}
                            </IconButton>
                          </div>
                        </Box>
                      );
                    })}
                  </li>
                </ul>
              </div>
              <div className="contentBox">
                <h3>Variables</h3>
                <ul>
                  {current_trial?.variables?.map((variable, index) => {
                    return (
                      <li>
                        <div className="variableList">
                          <Box className="data" width={"60%"}>
                            <p>{variable.name}</p>
                          </Box>
                          <Box className="data" width={"20%"}>
                            <InputLabel
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              <Box fontWeight={"600"}>{variable.inputType}</Box>
                            </InputLabel>
                            {/* <NativeSelect
                              defaultValue={"List"}
                              inputProps={{
                                name: "type",
                                id: "uncontrolled-native",
                              }}
                            >
                              <option value={"List"}>List</option>
                              <option value={"List"}>List</option>
                              <option value={"List"}>List</option>
                            </NativeSelect> */}
                          </Box>
                          <Box className="data" width={"20%"}>
                            {/* <p>16:35:00</p> */}
                            {/* {variable.schedule.map((time) => (
                              <p>{time}</p>
                            ))} */}
                            {variable?.schedule.join(", ")}
                          </Box>
                          <div>
                            <IconButton
                              onClick={() => {
                                // onDelete(item);
                                setIsVOpen(true);
                                setVIndex(index);
                                // navigate(`/users/trial-list/${user?._id}`);
                              }}
                              disabled
                              className="trash-icon"
                              aria-label="trial"
                            >
                              <EditIcon />
                              {/* <TrialI /> */}
                            </IconButton>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="contentBox">
                <div className="phases">
                  <div className="phaseDuration">
                    <TextField
                      label="Phase Duration"
                      defaultValue="2"
                      fullWidth
                      disabled
                      value={current_trial?.phaseDuration}
                    />
                  </div>
                  <div className="phaseNumber">
                    <TextField
                      label="Phase Number"
                      defaultValue="5"
                      fullWidth
                      disabled
                      value={current_trial?.phaseNumber}
                    />
                  </div>
                  <div className="phasePattern">
                    <TextField
                      label="Phase Pattern"
                      defaultValue="alternating"
                      fullWidth
                      disabled
                      value={
                        current_trial?.phasePattern == "alter"
                          ? "alternating"
                          : "test"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="contentBox">
                <div className="dates">
                  <div className="startdate">
                    <TextField
                      id="date"
                      label="Start Date"
                      type="date"
                      format={"MM-DD-YYYY"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                      value={moment(current_trial?.startDate).format(
                        "YYYY-MM-DD"
                      )}
                    />
                  </div>
                  <div className="endDate">
                    <TextField
                      id="date"
                      label="End Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                      value={moment(current_trial?.endDate).format(
                        "YYYY-MM-DD"
                      )}
                    />
                  </div>
                </div>
              </div>
            </Box>
            {/* <Box>
              <Button variant="contained">Go back</Button>
            </Box> */}
            {/* {current_trial?.graphList?.length == 1 &&
            lineOptions &&
            lineSeries?.length ? (
              <div id="chart">
                <ReactApexChart
                  options={lineOptions}
                  series={lineSeries}
                  type="line"
                  height={350}
                />
              </div>
            ) : (
              <></>
            )} */}
            {/* {current_trial?.graphList?.length == 2 ? ( */}

            <div className="all-result-container">
              <h1>Box Plot</h1>
              <div className="boxplot-dropdown-container">
                <select
                  className="filter-type drop-down-variable"
                  onChange={(e) => {
                    setBoxPlotType(e.target.value);
                  }}
                  value={boxPlotType}
                >
                  <option value="myResultBoxPlotValueList">My Result</option>
                  <option value="boxPlotValueList">All Result</option>
                </select>
                <select
                  className="filter-type drop-down-variable"
                  onChange={(e) => {
                    // setCurrentBoxPlotVariable(boxPlotList[e.target.value]);
                    setboxPlotVariableIndex(e.target.value);
                  }}
                  value={boxPlotVariableIndex}
                >
                  {current_trial &&
                    current_trial[boxPlotType].length &&
                    current_trial[boxPlotType].map((variable, index) => {
                      return (
                        <option key={index} value={index}>
                          {" "}
                          {variable.info.name} ({variable.info.from.toUpperCase()})
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="boxplot-t-p-test-div">
                <div>
                  <span>
                    <strong>T test Value : </strong>
                  </span>
                  <span> {currentBoxPlotVariable?.tTestValue?.toFixed(4)}</span>
                </div>
                <div>
                  <span>
                    <strong>P value(One Tailed) : </strong>
                  </span>
                  <span>
                    {currentBoxPlotVariable?.pOneTailedValue?.toFixed(4)}
                  </span>
                </div>
                <div>
                  <span>
                    <strong>P value(Two Tailed) : </strong>
                  </span>
                  <span>
                    {currentBoxPlotVariable?.pTwoTailedValue?.toFixed(4)}
                  </span>
                </div>
              </div>
              <div id="chart">
                <ReactApexChart
                  options={optinsBox}
                  series={boxSeries}
                  type="boxPlot"
                  height={350}
                />
              </div>
            </div>

            {/* ) // : ( // <></>
            // )} */}
          </Container>
        </div>
      </div>
      <EditTrialIntervention
        key={isEditOpen}
        index={index}
        // onEditPerform={(res) => {
        //   performAction(res);
        // }}
        // handleClickOpen={handleClickOpen}
        handleClose={() => setIsEditOpen(false)}
        open={isEditOpen}
      />
      <EditTrialVariables
        key={isVOpen}
        index={VIndex}
        handleClose={() => setIsVOpen(false)}
        open={isVOpen}
        data={variableData}
      />
    </div>
  );
}

export default Trial;
