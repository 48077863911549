import axios from "axios";
import { config } from "../constants/config";
// import { encrypt } from "../hippa";
// https://nof1.app/backend/api/v1/common
// ${URL.imageUrl}/api/v1/common
//dev server: http://54.201.160.69:3267/
const API = axios.create({
  // baseURL: `http://54.201.160.69:3267/api/v1/common`,
  baseURL: config.imageBaseCommonUrl
});

const header = () => {
  let token = JSON.parse(localStorage.getItem("token")).token;

  let abc = {
    headers: {
      Authorization: `Bearer ${token}`,
      userType: "admin",
    },
  };
  return abc;
};

const formDataFun = (formData) => {
  var formdataAdd = new FormData();
  formdataAdd.append("image", formData);
  return formdataAdd;
};

export const imageUpload = (formData) =>
  API.post("/image", formDataFun(formData));
