import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { styled } from "@mui/material/styles";
import frtSvg from "../../../assets/frontArrow.svg";
import bckSvg from "../../../assets/backArrow.svg";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import SystemUpdateAltRoundedIcon from '@mui/icons-material/SystemUpdateAltRounded';
import ReactPaginate from "react-paginate";
import {
  deleteUser,
  setCurrentUser,
  userData,
  usersConvert,
} from "../../../slice/usersSlice";
import { Box } from "@mui/system";
import DeleteModal from "../../common/DeleteModal";
import { deletetrial, exportTrialOfUser, getTrials } from "../../../slice/adminTrialSlice";
import Pagination from "../../common/Pagination";
import { Link, useNavigate } from "react-router-dom";
import ConfirmModel from "../../common/confirmModel";
import { config } from "../../../constants/config";
import { toast } from "react-toastify";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const TrialList = (props) => {
  let dispatch = useDispatch();
  const { all_trial } = useSelector((store) => ({
    all_trial: store.admin_trial.all_trial,
  }));
  const [pageCount, setPageCount] = useState(0);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  console.log({ all_trial, pageCount, currentPage });
  const navigate = useNavigate();

  const [trialList, setTrialList] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteTrialId, setDeleteTrialId] = useState(false);

  const onCheckDelete = (event, trialId) => {
    const { checked } = event.target;
    if (checked) {
      setDeleteList((prev) => [...prev, trialId]);
    } else {
      setDeleteList((prev) => prev.filter((id) => id !== trialId));
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
    // fetchData(data.selected + 1, "");
    dispatch(
      getTrials({
        page: data?.selected + 1,
        limit: 10,
        search: searchData,
        status: "all",
      })
    );
  };

  const handlePageClickEvent = (page) => {
    // props.setPage(page);
    setCurrentPage(page);
    // setDummyReload(new Date().toLocaleTimeString());
    // fetchData(data.selected + 1, "");
    dispatch(
      getTrials({
        page: page,
        limit: 10,
        search: searchData,
        status: props.status,
      })
    );
  };

  const onDelete = ({ trialId }) => {
    setDeleteTrialId(null);
    setDeleteList([]);
    setOpen(false);
    dispatch(
      deletetrial({
        trialId: { trialId: trialId },
        page: currentPage,
        limit: 10,
        status: props.status,
      })
    );
  };

  const handleExport = async (trialId) => {
    const result = await exportTrialOfUser(trialId)
    if (result) {
      window.open(`${config.imageUrl}/${result.path}`, "_blank")
    } else {
      toast.error("Something went wrong!")
    }
  }

  useEffect(() => {
    setTrialList([]);
    setPageCount(0);
  }, [props.status]);

  useEffect(() => {
    dispatch(
      getTrials({
        page: currentPage,
        limit: 10,
        search: searchData,
        status: props.status,
      })
    );
  }, []);
  useEffect(() => {
    setTrialList(all_trial?.list || []);
    setPageCount(Math.ceil(all_trial?.count / 10));
  }, [all_trial]);

  const handleSearch = (event) => {
    const { value: searchData } = event.target;
    setSearchData(searchData);
    dispatch(
      getTrials({
        page: currentPage,
        limit: 10,
        search: searchData,
        status: props.status,
      })
    );
  };

  return (
    <div>
      <Grid item xs={6} sm={6} md={9}>
        <Box
          justifyContent={"space-between"}
          className="d-flex justify-content-between searchBox"
        >
          <input
            className="searchBarWithButton"
            placeholder="Search...."
            type="text"
            // disabled
            value={searchData}
            onChange={(e) => {
              handleSearch(e);
            }}
          />
        </Box>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow sx={{ color: "red" }}>
                {!props.status && (
                  <StyledTableCell align="left"></StyledTableCell>
                )}
                <StyledTableCell align="left">Sr. No</StyledTableCell>
                <StyledTableCell align="left">Name</StyledTableCell>
                <StyledTableCell align="left">Created By</StyledTableCell>
                <StyledTableCell align="left">Idea</StyledTableCell>
                <StyledTableCell align="left">Category</StyledTableCell>
                {!props.status && (
                  <StyledTableCell align="left">Action</StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {trialList && trialList.length ? (
                trialList?.map((trial, index) => {
                  // AdminPanelSettingsIcon;

                  return (
                    <StyledTableRow>
                      {!props.status && (
                        <StyledTableCell component="th" scope="row">
                          <input
                            type="checkbox"
                            checked={
                              deleteList.filter((ids) => ids === trial._id)
                                .length
                                ? true
                                : false
                            }
                            onChange={(event) =>
                              onCheckDelete(event, trial._id)
                            }
                          />
                        </StyledTableCell>
                      )}
                      <StyledTableCell component="th" scope="row">
                        {(currentPage - 1) * 10 + index + 1}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {trial.name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <Link
                          style={{
                            textDecoration: "underline",
                            color: "blue",
                          }}
                          to={`/users/trial-list/${trial.createdBy?.[0]?._id}`}
                        >
                          {" "}
                          {trial.createdBy?.[0]?.name}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {trial.idea}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {trial.category.name}
                      </StyledTableCell>
                      {!props.status && (
                        <StyledTableCell component="th" scope="row">
                          <IconButton
                            onClick={() => {
                              setDeleteTrialId([trial?._id]);
                              setOpen(true);
                            }}
                            className="trash-icon"
                            aria-label="delete"
                            title="Delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              navigate(
                                `/users/${trial.createdBy?.[0]?._id}/trial/${trial._id}`
                              );
                            }}
                            className="trash-icon"
                            aria-label="delete"
                            title="View"
                          >
                            <VisibilityIcon style={{ color: "#18a6d5" }} />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              handleExport(trial?._id)
                            }}
                            className="trash-icon"
                            aria-label="delete"
                            title="Export"
                          >
                            <SystemUpdateAltRoundedIcon style={{ color: "#3c4b64" }} />
                          </IconButton>
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    align="center"
                    component="th"
                    scope="row"
                    colSpan={7}
                  >
                    {searchData ? "No trials found" : "Loading..."}
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {deleteList.length ? (
                <StyledTableRow>
                  <StyledTableCell
                    align="right"
                    component="th"
                    scope="row"
                    colSpan={7}
                  >
                    <Button
                      onClick={() => {
                        setDeleteTrialId(deleteList);
                        setOpen(true);
                      }}
                      aria-label="delete"
                      variant="contained"
                    >
                      Delete selected
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {pageCount && pageCount > 1 ? (
          <div className="myPaginationBottom">
            <Pagination
              totalPage={pageCount}
              currentActionPage={currentPage}
              previousIcon={bckSvg}
              nextIcon={frtSvg}
              defaultActivePage={currentPage}
              onPageChange={handlePageClick}
              onhandlePageChange={handlePageClickEvent}
            />
          </div>
        ) : (
          <></>
        )}
      </Grid>
      <ConfirmModel
        open={open}
        handleClick={onDelete}
        handleClose={() => setOpen(false)}
        message={"Are you sure you want to delete this Trial?"}
        secondMessage="This trial will be deleted from all the users."
        okText="Delete"
        trialId={deleteTrialId}
      />
    </div>
  );
};

export default TrialList;
