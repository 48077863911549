import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../helpers/RestClient";
import { encrypt, decrypt, showSuccessToast } from "../helpers/common";
import GLOBALS from "../constants";
const { MESSAGES } = GLOBALS;

export const allList = createAsyncThunk(
  "/list",
  async ({ page, search }, { dispatch }) => {
    try {
      const response = await api.intervension(page, search);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(setList(decrypt_data.result));
      }
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

export const editList = createAsyncThunk(
  "/goalEdit",
  async ({ values }, { dispatch, getState }) => {
    let id = getState().intervention?.current_item._id;
    try {
      const response = await api.intervensionEditSubmit(id, values);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(allList({ page: 1, search: "" }));
        dispatch(setCurrentItem({}));
        showSuccessToast(MESSAGES.INTER_EDIT);
      }
    } catch (err) {}
  }
);

export const addListItem = createAsyncThunk(
  "/goalAdd",
  async ({ values }, { dispatch, getState }) => {
    try {
      const response = await api.intervensionManagament(values);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(allList({ page: 1, search: "" }));
        dispatch(setCurrentItem({}));
        showSuccessToast(MESSAGES.Inter_ADD);
      }
    } catch (err) {}
  }
);

export const deleteItem = createAsyncThunk(
  "/categoryDelete",
  async (data, { dispatch, getState }) => {
    try {
      const response = await api.intervensionDelete(data.id);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(allList({ page: 1, search: "" }));
        dispatch(setCurrentItem({}));
        showSuccessToast(MESSAGES.INTER_DEL);
      }
    } catch (err) {}
  }
);

export const getAdminInterventionList = createAsyncThunk(
  "/intervention/admin",
  async ({ categoryId }, { dispatch }) => {
    try {
      const response = await api.interventionForAdmin(categoryId);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(setList(decrypt_data.result));
      }
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  user: null,
  error: "",
  loading: false,
};
const interventionSlice = createSlice({
  name: "intervention",
  initialState: {
    list: [],
    current_item: {},
    // loading: false
  },
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    setCurrentItem: (state, action) => {
      state.current_item = action.payload;
    },
  },
  extraReducers: {},
});

export const { setList, setCurrentItem } = interventionSlice.actions;
export default interventionSlice.reducer;
