const reportContentObject = {
  forumcomments: {
    title: "Forum Comment",
    fields: {
      "Reported Comment": "content.content.comment",
      "Comment Date": "content.content.createdAt",
      "Forum Title": "content.forumContent.title",
      "Forum Hit Line": "content.forumContent.hitLine",
      status: "content.status",
    },
  },
  forums: {
    title: "Forum",
    fields: {
      "Forum Title": "content.content.title",
      "Forum Hit Line": "content.content.hitLine",
      "Forum Date": "content.content.createdAt",
      status: "content.status",
    },
  },
  trialcomments: {
    title: "Trial Comment",
    fields: {
      "Reported Comment": "content.content.comment",
      "Comment Date": "content.content.createdAt",
      "Trial Name": "content.trialContent.name",
      "Trial Idea": "content.trialContent.idea",
      status: "content.status",
    },
  },
  trials: {
    title: "Trial",
    fields: {
      "Trial Name": "content.content.name",
      "Trial Date": "content.content.createdAt",
      "Trial Idea": "content.content.idea",
      status: "content.status",
    },
  },
};

export default reportContentObject;
