import GLOBALS from "../../constants";
import * as React from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
// import { Link } from "@mui/material";

const { SIDE_MENU } = GLOBALS;

function SideBar({ setToggle, toggle }) {
  function toggleSidebar() {
    setToggle(!toggle);
  }
  return (
    <div className="sidebar-menu">
      {SIDE_MENU.map((menuItem, key) => {
        return (
          <>
            {/* {menuItem.title !== "Trials" &&  */}
            <Box key={menuItem.id}>
              <Link
                to={menuItem.link}
                underline="none"
                className="d-flex align-items-center menu-item"
              >
                <span className="Icon">
                  <i className={menuItem.icon}></i>
                </span>
                <label>{menuItem.title}</label>
              </Link>
            </Box>
            {/* } */}
          </>
        );
      })}
      <div className="trigerIcon" onClick={toggleSidebar}>
        <i class="fa-solid fa-chevron-right"></i>
      </div>
    </div>
  );
}

export default SideBar;
