import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import CIcon from "@coreui/icons-react";
import { cilJustifyCenter, cilPencil, cilTrash } from "@coreui/icons";
import { CButton } from "@coreui/react";
import IconButton from "@mui/material/IconButton";
import FitbitIcon from "@mui/icons-material/Fitbit";
import frtSvg from "../../../assets/frontArrow.svg";
import bckSvg from "../../../assets/backArrow.svg";
import adminB from "../../../assets/img/admin_b.svg";
import Table from "@mui/material/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonIcon from "@mui/icons-material/Person";
import { deleteGoal, goalList } from "../../../slice/goalSlice";
import ReactPaginate from "react-paginate";
import moment from "moment";
import {
  setCurrentVariable,
  deleteVariable,
  variableList,
} from "../../../slice/variableSlice";
import DeleteModal from "../../common/DeleteModal";
import Pagination from "../../common/Pagination";
import {
  actionOnReportContent,
  reportById,
  reportList,
} from "../../../slice/reportSlice";
import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import { Box, Button, Typography } from "@mui/material";
import Content from "./Content";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

// import { actionOnReportContent } from "../../../helpers/RestClient";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ReportDetail(props) {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useParams();
  const { current_report } = useSelector((state) => ({
    current_report: state.report.current_report,
  }));
  //   let { onButtonClick } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [report, setReport] = useState(null);

  const handleOnBlock = () => {
    dispatch(
      actionOnReportContent({
        reportId: router.reportId,
        status: "block",
      })
    );
  };
  const handleOnIgnore = () => {
    dispatch(
      actionOnReportContent({
        reportId: router.reportId,
        status: "ignore",
      })
    );
  };
  useEffect(() => {
    dispatch(reportById({ reportId: router.reportId }));
  }, []);
  useEffect(() => {
    setReport(current_report[0]);
  }, [current_report]);

  useEffect(() => {
  }, [report]);

  return (
    <>
      <div className="dashboard-wrapper d-flex flex-column flex-fill overflow-auto">
        <Header setToggle={setToggle} toggle={toggle} />
        <div className="inner-wrapper">
          <div
            className={`left-col d-flex flex-column  ${toggle ? "collapseSidebar" : ""
              } `}
          >
            <SideBar setToggle={setToggle} toggle={toggle}></SideBar>
          </div>

          <div
            className={`right-col d-flex flex-column ${toggle ? "expandDashboard" : ""
              }`}
          >
            <Box
              sx={{
                textAlign: "start",
                marginBottom: "0.9em",
                display: "flex",
                alignItems: "center",
                marginTop: "3em",
                justifyContent: "flex-start",
                cursor: "pointer",
                width: "20%",
              }}
              onClick={(e) => {
                navigate(-1);
              }}
            >
              <ArrowBackIosIcon />
              <Box component="span" sx={{ fontSize: "25px" }}>
                Back
              </Box>
            </Box>
            <Typography variant="h4" class="page_title">
              Reports Details
            </Typography>
            <div>
              <Content content={report?.reportContent} />
            </div>
            <div>
              <h3>Reported User</h3>
              <Grid item xs={6} sm={6} md={9}>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow sx={{ color: "red" }}>
                        <StyledTableCell align="left">Sr. No</StyledTableCell>
                        <StyledTableCell align="left">
                          User Name
                        </StyledTableCell>
                        {/* <StyledTableCell align="left">Content</StyledTableCell> */}
                        <StyledTableCell align="left">
                          Reason Type
                        </StyledTableCell>
                        <StyledTableCell align="left">Reason</StyledTableCell>
                        <StyledTableCell align="left">
                          Report Date
                        </StyledTableCell>
                        {/* <StyledTableCell align="left">Delete</StyledTableCell> */}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {report &&
                        report?.reportedUser?.map((item, index) => {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell component="th" scope="row">
                                {index + 1}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {item?.userName}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {item?.reasonType}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {item?.reason}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {moment(item?.reportedAt).format("MM-DD-YYYY")}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                    </TableBody>
                    {/* )} */}
                  </Table>
                </TableContainer>

                {/* <DeleteModal
        isOpen={open}
        handleClose={handleClose}
        onDelete={onDelete}
      /> */}
              </Grid>
            </div>
            <div className="report-action-button-container">
              <Button
                variant="contained"
                color="error"
                onClick={handleOnBlock}
                disabled={["block", "ignore"].includes(
                  report?.reportContent?.status
                )}
              >
                Block
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={handleOnIgnore}
                disabled={["block", "ignore"].includes(
                  report?.reportContent?.status
                )}
              >
                Ignore
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* <EditVariable
        key={isEditOpen}
        // onEditPerform={(res) => performAction(res)}
        handleClose={() => {
          setEditOpen(false);
          dispatch(setCurrentVariable({}));
        }}
        open={isEditOpen}
        // id={id}
      /> */}
      <ToastContainer />
    </>
  );
}

export default ReportDetail;
