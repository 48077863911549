import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../helpers/RestClient";
import * as apiImage from "../helpers/ImageCommon";

import {
  encrypt,
  decrypt,
  showSuccessToast,
  showErrorToast,
} from "../helpers/common";

import GLOBALS from "../constants";

const { MESSAGES } = GLOBALS;

export const categoryList = createAsyncThunk(
  "/category",
  async ({ page, search, limit }, { dispatch }) => {
    try {
      const response = await api.category(page, search, limit);
      const decrypt_data = await decrypt(response.data.data);

      if (decrypt_data.code === 200) {
        dispatch(setCategoryList(decrypt_data.result));
      } else {
        showErrorToast(decrypt_data.message);
      }
    } catch (err) {
      showErrorToast(err?.message || "Internal Server Error");
    }
  }
);

export const editCategory = createAsyncThunk(
  "/categoryEdit",
  async ({ values }, { dispatch, getState }) => {

    let id = getState().category?.current_category._id;
    try {
      const response = await api.categoryEditSubmit(id, values);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(categoryList({ page: 1, search: "", limit: 10 }));
        dispatch(setCurrentCategory({}));
        showSuccessToast(MESSAGES.CAT_EDIT);
      } else {
        showErrorToast(decrypt_data.message);
      }
    } catch (err) {
      showErrorToast(err?.message || "Internal Server Error");
    }
  }
);

export const addCategory = createAsyncThunk(
  "/categoryAdd",
  async ({ values }, { dispatch, getState }) => {
    // let id = getState().category?.current_category._id;
    try {
      const response = await api.categoryManagament(values);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(categoryList({ page: 1, search: "", limit: 10 }));
        dispatch(setCurrentCategory({}));
        showSuccessToast(MESSAGES.ADD);
      } else {
        showErrorToast(decrypt_data.message);
      }
    } catch (err) {
      showErrorToast(err?.message || "Internal Server Error");
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "/categoryDelete",
  async (data, { dispatch, getState }) => {
    try {
      const response = await api.categoryDelete(data.id);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(categoryList({ page: 1, search: "", limit: 10 }));
        dispatch(setCurrentCategory({}));
        showSuccessToast(MESSAGES.CAT_DEL);
      } else {
        showErrorToast(decrypt_data.message);
      }
    } catch (err) {
      showErrorToast(err?.message || "Internal Server Error");
    }
  }
);

export const uploadImage = createAsyncThunk(
  "/image",
  async (values, { dispatch }) => {
    try {
      const response = await apiImage.imageUpload(values);
      if (response) {
        // const data = await encrytedBody(response.data.data);
        // if (data.data.code == 200) {
        //   // navigate("/admin/category");
        // }
        dispatch(imageRmove(response?.data?.result));
        // return response?.data?.result;+
      }
      // return response;
      //   toast.success("Login Successfully");
    } catch (err) {
      // return rejectWithValue(err.response.data);
      showErrorToast(err?.message || "Internal Server Error");
    }
  }
);
export const getAdminCategoryList = createAsyncThunk(
  "/categorylist/something/admin",
  async ({}, { dispatch }) => {
    try {
      const response = await api.categoryForAdmin();
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(setCategoryList(decrypt_data.result));
        // return decrypt_data.result;
      }
      return;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  user: null,
  error: "",
  loading: false,
  image: "",
};

const categorySlice = createSlice({
  name: "category",
  initialState: {
    all_categories: [],
    current_category: {},
    image: "",
    // loading: false
  },
  reducers: {
    setCategoryList: (state, action) => {
      state.all_categories = action.payload;
    },
    setCurrentCategory: (state, action) => {
      state.current_category = action.payload;
    },
    imageRmove: (state, action) => {
      state.image = action.payload;
    },
    // imageAdd: (state, action) => {
    //   state.im
    // }
  },
  // extraReducers: {
  //   [uploadImage.fulfilled]: (state, action) => {
  //     state.image = action.payload;
  //   },
  // },
});

export const { setCategoryList, setCurrentCategory, imageRmove } =
  categorySlice.actions;
export default categorySlice.reducer;
