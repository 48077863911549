import React, { Component } from "react";
// import Login from "../login/Login";
// import User from "../users/User";
// import Category from "../category/Category";
// import Goal from "../goal/Goal";
// import Intervention from "../intervention/Intervention";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../../App.scss";

//
import { CSpinner } from "@coreui/react";
import { Suspense } from "react";
import ProtectedRoutes from "../../ProtectedRoutes";
import UserTrialList from "../../components/dashboard/user/UserTrialList";
import Trial from "../../components/dashboard/user/Trial";
import Report from "../report/Report";
import ReportDetail from "../../components/dashboard/report/ReportDetail";
import AdminTrial from "../adminTrials/AdminTrial";
import AddAdminTrial from "../../components/dashboard/adminTrial/AddAdminTrial";
import NotFound from "../../components/common/404";
import Trials from "../trials/trials";
const Goal = React.lazy(() => import("../goal/Goal"));
const Login = React.lazy(() => import("../login/Login"));
const PrivacyPolicy = React.lazy(() =>
  import("../privacyPolicy/PrivacyPolicy")
);
const AdminUser = React.lazy(() => import("../adminUsers/AdminUser"));
const User = React.lazy(() => import("../users/User"));
const Category = React.lazy(() => import("../category/Category"));
const Intervention = React.lazy(() => import("../intervention/Intervention"));
const Variable = React.lazy(() => import("../variable/Variable"));
const Dashboard = React.lazy(() => import("../dashboard/Dashboard"));

function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={
            <>
              <div className="loader-content">
                <CSpinner color="secondary" />
              </div>
            </>
          }
        >
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route element={<ProtectedRoutes />}>
              <Route exact path="/users" element={<User />} />
              <Route exact path="/admin-user" element={<AdminUser />} />
              <Route exact path="/category" element={<Category />} />
              <Route exact path="/goals" element={<Goal />} />
              <Route exact path="/intervention" element={<Intervention />} />
              <Route exact path="/variable" element={<Variable />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/report" element={<Report />} />
              <Route exact path="/admin-trial" element={<AdminTrial />} />
              <Route exact path="/trials" element={<Trials />} />
              <Route
                exact
                path="/admin-trial/add"
                element={<AddAdminTrial />}
              />
              <Route
                exact
                path="/report/:reportId"
                element={<ReportDetail />}
              />
              <Route
                exact
                path="/users/trial-list/:id"
                element={<UserTrialList />}
              />
              <Route
                exact
                path="/users/:userId/trial/:id"
                element={<Trial />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}
export default App;
