export const config = {
    imageUrl: "https://nof1.app/backend",
    imageBaseCommonUrl: "https://nof1.app/backend/api/v1/common",
    baseURL: "https://nof1.app/backend/api"
}

// export const config = {
//     imageUrl: "http://54.201.160.69:3267",
//     imageBaseCommonUrl: "http://54.201.160.69:3267/api/v1/common",
//     baseURL: "http://54.201.160.69:3267/api"
// }

// export const config = {
//     imageUrl: "http://localhost:3267",
//     imageBaseCommonUrl: "http://localhost:3267/api/v1/common",
//     baseURL: "http://localhost:3267/api"
// }