import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../helpers/RestClient";
import { encrypt, decrypt } from "../helpers/common";

export const overview = createAsyncThunk(
  "dashbaord/overview",
  async (dispatch, { rejectWithValue }) => {
    try {
      const response = await api.dashbaordOverview();
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(setOverview(decrypt_data.result));
      }
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  user: null,
  error: "",
  loading: false,
};
const overviewSlice = createSlice({
  name: "userManagment",
  initialState: {
    overview: {
      user: 0,
      variables: 0,
      goal: 0,
      internvention: 0,
    },
    error: "",
    loading: false,
  },
  reducers: {
    setOverview: (state, action) => {
      state.overview = action.payload;
    },
  },
  extraReducers: {
    [overview.pending]: (state, action) => {
      state.loading = true;
    },
    [overview.fulfilled]: (state, action) => {

      state.loading = false;
      // localStorage.setItem("profile", JSON.stringify({ ...action.payload }));
      // state.user = action.payload;
    },
    [overview.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export const { setOverview } = overviewSlice.actions;
export default overviewSlice.reducer;
