import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../helpers/RestClient";
import * as apiImage from "../helpers/ImageCommon";
import Chart from "react-apexcharts";

import {
  encrypt,
  decrypt,
  showSuccessToast,
  showErrorToast,
} from "../helpers/common";
import GLOBALS from "../constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const { MESSAGES } = GLOBALS;

export const trialData = createAsyncThunk(
  "user-manage/users/:userId/trials",
  async ({ id }, { dispatch }) => {
    try {
      const response = await api.trialManagment(id);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        // dispatch(setUserList(decrypt_data.result));
        return decrypt_data.result;
      }
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

export const addTrial = createAsyncThunk(
  "/trial/add",
  async ({ input }, { dispatch }) => {
    try {

      const response = await api.addTrialFromAdmin(input);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {


        // alert("Trial Added Successfully!");
        showSuccessToast("Trial Added Successfuly");

        // return decrypt_data.result;
      } else {
        showErrorToast(decrypt_data.message);
        // alert(decrypt_data.message);
      }
    } catch (err) {
      showErrorToast(err?.message);
    }
  }
);

export const getAdminTrials = createAsyncThunk(
  "/admin/trial-list",
  async ({ page, limit }, { dispatch }) => {
    try {

      const response = await api.getAdminTrials(page, limit);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        // dispatch(setUserList(decrypt_data.result));
        // return decrypt_data.result;
        dispatch(setAdminTrialList(decrypt_data.result));
      }
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

export const getTrials = createAsyncThunk(
  "/admin/user-trial-list",
  async ({ page, limit, search, status }, { dispatch }) => {
    try {
      dispatch(setTrialList(null));
      const response = await api.getTrials(page, limit, search, status);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        // dispatch(setUserList(decrypt_data.result));
        // return decrypt_data.result;
        console.log({ decrypt_data })
        dispatch(setTrialList(decrypt_data.result));
      }
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

export const deletetrial = createAsyncThunk(
  "/admin/user-trial-list/delete",
  async ({ trialId, page, limit, status }, { dispatch }) => {
    try {
      console.log({ trialId })
      const response = await api.deleteTrial(trialId);

      const decrypt_data = await decrypt(response.data.data);
      console.log({ decrypt_data })
      if (decrypt_data.code == 200) {
        toast.success("Trial deleted successfully!")
        const response = await api.getTrials(page, limit, status);
        const decrypt_data = await decrypt(response.data.data);
        if (decrypt_data.code == 200) {
          // dispatch(setUserList(decrypt_data.result));
          // return decrypt_data.result;
          console.log({ decrypt_data })
          dispatch(setTrialList(decrypt_data.result));
        }
      }
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

export const setCurrentTrialOfUser = createAsyncThunk(
  "/trialId",
  async ({ userId, trialId }) => {
    try {
      const response = await api.getTrialById(userId, trialId);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        // dispatch(setUserList(decrypt_data.result));
        return decrypt_data.result;
      }
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

export const exportTrialOfUser = async (trialId) => {
  try {
    const response = await api.exportTrial(trialId);
    const decrypt_data = await decrypt(response.data.data);
    console.log({ decrypt_data })
    if (decrypt_data.code == 200) {
      // dispatch(setUserList(decrypt_data.result));
      return decrypt_data.result;
    }
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
}
export const uploadImageForAdminTrial = createAsyncThunk(
  "/trial/image",
  async (values, { dispatch }) => {
    try {
      const response = await apiImage.imageUpload(values);
      if (response) {
        dispatch(
          setCurrentTrialField({
            trial_field_key: "image",
            trial_field_value: response?.data?.result,
          })
        );
      }
    } catch (err) {
      showErrorToast(err?.message || "Internal Server Error");
    }
  }
);

const adminTrialSlice = createSlice({
  name: "admin-trial",
  initialState: {
    admin_all_trial: [],
    admin_current_trial: {
      name: "",
      idea: "",
      image: "",
      isPublic: true,
      category: "",
      intervention: [
        // {
        //   id: "636c91449c36640bd17d2d4b",
        //   name: "1Willow bark tea",
        //   desc: "Willow bark tea1",
        //   backgroundLiterature: "Willow bark tea",
        //   tag: "A",
        //   perDayCount: 1,
        //   schedule: ["10:03"],
        //   _id: "63995233bde57270b19be029",
        // },
        // {
        //   name: "Without 1Willow bark tea",
        //   tag: "B",
        //   perDayCount: 1,
        //   schedule: ["10:03"],
        //   _id: "63995233bde57270b19be02a",
        // },
      ],
      variables: [],
      phaseDuration: 7,
      phaseNumber: 1,
      phasePattern: "alter",
    },
  },
  reducers: {
    setAdminCurrentTrial: (state, action) => {
      state.admin_current_trial = action.payload;
    },
    setCurrentTrialField: (state, action) => {
      state.admin_current_trial[action.payload.trial_field_key] =
        action.payload.trial_field_value;
    },
    setIntervention: (state, action) => {
      switch (action.payload.type) {
        case "add": {
          state.admin_current_trial.intervention.push(
            action.payload.intervention
          );
          break;
        }
        case "delete": {
          state.admin_current_trial.intervention.splice(
            action.payload.dIndex,
            1
          );
          break;
        }
        case "edit": {
          state.admin_current_trial.intervention[action.payload.index] =
            action.payload.intervention;
          break;
        }
        default: {
        }
      }
    },
    setVariable: (state, action) => {
      switch (action.payload.type) {
        case "add": {
          state.admin_current_trial.variables.push(action.payload.variables);
          break;
        }
        case "delete": {
          state.admin_current_trial.variables.splice(action.payload.dIndex, 1);
          break;
        }
        case "edit": {
          state.admin_current_trial.variables[action.payload.index] =
            action.payload.variables;
          break;
        }
        default: {
        }
      }
    },
    setAdminTrialList: (state, action) => {
      state.admin_all_trial = action.payload;
    },
    setTrialList: (state, action) => {
      state.all_trial = action.payload;
    },
    clearAdminCurrentTrial: (state, action) => {
      state.admin_all_trial = {};
    },
    clearCurrentTrial: (state, action) => {
      state.all_trial = {};
    },
  },
  extraReducers: {},
});

// export const { setUserList, setLogout, setCurrentUser, imageUserRmove } =
//   usersSlice.actions;
export const {
  setAdminCurrentTrial,
  setCurrentTrialField,
  setSchedule,
  setVariable,
  setIntervention,
  setAdminTrialList,
  setTrialList
} = adminTrialSlice.actions;
export default adminTrialSlice.reducer;
