//report content slice //
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as api from "../helpers/RestClient";
import {
  encrypt,
  decrypt,
  showSuccessToast,
  showErrorToast,
} from "../helpers/common";
import GLOBALS from "../constants";
const { MESSAGES } = GLOBALS;
// import { decrypt, encrypt } from "../hippa";
// action
const encrytedBody = async (response) => {
  const data = response;
  const encrypted = await decrypt(response);
  response = { data: encrypted };
  return response;
};

export const reportList = createAsyncThunk(
  "/report",
  async ({ page, limit, status }, { rejectWithValue }) => {
    try {
      const response = await api.reportList(status, page, limit);
      if (response) {
        const data = await encrytedBody(response.data.data);
        return data.data.result;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const reportById = createAsyncThunk(
  "/report/reportById",
  async ({ reportId }, { rejectWithValue }) => {
    try {
      const response = await api.reportById(reportId);
      if (response) {
        const data = await encrytedBody(response.data.data);
        return data.data.result;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const actionOnReportContent = createAsyncThunk(
  "/report/:reportId",
  async (data, { dispatch, getState }) => {
    try {
      const response = await api.actionOnReportContent(data.reportId, {
        status: data.status,
      });
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        showSuccessToast(MESSAGES.REPORT_ACTION_SUCCESS);
        dispatch(reportById({ reportId: data.reportId }));
      } else {
        showErrorToast(decrypt_data.message);
      }
    } catch (err) {
      showErrorToast(MESSAGES.REPORT_ERROR);
    }
  }
);

const reportSlice = createSlice({
  name: "report",
  initialState: {
    all_report: [],
    error: "",
    loading: false,
    current_report: {},
  },
  reducers: {
    setCurrentReport: (state, action) => {
      state.current_report = action.payload;
    },
    // setLogout: (state, action) => {
    //   localStorage.clear();
    //   state.user = null;
    // },
  },
  extraReducers: {
    [reportList.fulfilled]: (state, action) => {
      state.all_report = action.payload;
    },
    [reportById.fulfilled]: (state, action) => {
      state.current_report = action.payload;
    },
  },
});

export const { setCurrentReport } = reportSlice.actions;

export default reportSlice.reducer; //this is the default AuthReducer
