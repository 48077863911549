import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIntervention } from "../../../slice/adminTrialSlice";
import { getAdminInterventionList } from "../../../slice/interventionSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { hhMMTotimeStamp, timeStampToHHMMstring } from "../../../helpers/date";
import { showErrorToast } from "../../../helpers/common";
import moment from "moment";

// import { setSchedule } from "../../../slice/trialSlice";

const InterventionDialogue = (props) => {
  const dispatch = useDispatch();

  let { open, handleClose, index, handleInterventionIndex } = props;
  const [selectedIntervention, setSelectedIntervention] = useState(null);
  const [schedule, setSchedule] = useState([]);
  const [filteredList,setFilteredList] = useState([])

  const { admin_current_trial, interventionList } = useSelector((state) => ({
    admin_current_trial: state.admin_trial.admin_current_trial,
    interventionList: state.intervention.list.list
    // state.intervention.list.list?.filter(o => state.admin_trial.admin_current_trial?.intervention?.length > 0 ? state.admin_trial.admin_current_trial?.intervention?.some(a => o.name != a.name) : o),
  }));

  useEffect(() => {
    dispatch(getAdminInterventionList({}));
    return () => {
      setSchedule([]);
      setSelectedIntervention(null);
    };
  }, []);

  useEffect(() => {
    if (index !== undefined) {
      setSelectedIntervention(admin_current_trial.intervention[index]);
      setSchedule(admin_current_trial.intervention[index].schedule);
      setFilteredList([...interventionList])
      // interventionList= interventionList.filter(o=>admin_current_trial?.intervention?.length>0 ? admin_current_trial?.intervention?.some(a=>o.name != a.name):o)
    }else{
      const newArr =  interventionList?.filter(o => admin_current_trial?.intervention?.length > 0 ? admin_current_trial?.intervention?.some(a => o.name != a.name) : o)
      setFilteredList(newArr)
    }
  }, [index]);

  const handleClick = (type) => {
    // dispatch(imageRmove(""));
    switch (type) {
      case "Add": {
        if (admin_current_trial.intervention.find(o => o.name === selectedIntervention.name)) {
          showErrorToast("This intervention already exist!")
        } else {
          const scheduleArr = []
          schedule.map(o => {
            if (scheduleArr.includes(o)) {
              showErrorToast("Same schedule time can't be added")
              scheduleArr = null
            } else {
              scheduleArr.push(o)
            }
          })
          if (scheduleArr?.length > 0) {
            if (typeof index == "number") {
              dispatch(
                setIntervention({
                  index: index,
                  type: "edit",
                  intervention: {
                    // id: selectedIntervention._id,
                    // name: selectedIntervention.name,
                    ...selectedIntervention,
                    schedule: schedule,
                  },
                })
              );
            } else {
              dispatch(
                setIntervention({
                  type: "add",
                  intervention: {
                    // id: selectedIntervention._id,
                    // name: selectedIntervention.name,
                    ...selectedIntervention,
                    schedule: schedule,
                  },
                })
              );
            }
            handleClose();
            break;
          }
        }

      }
      case "close": {
        handleClose();
        break;
      }

      default:
        break;
    }

    handleInterventionIndex(undefined);
    setSchedule([])
    setSelectedIntervention(null)
  };
  return (
    <Dialog open={open}>
      <DialogTitle variant="body">Internvention</DialogTitle>
      <DialogContent>
        <>
          <div>
            <select
              style={{ width: "60%", minWidth: "400px", maxWidth: "450px" }}
              className="form-control"
              name=""
              id=""
              value={selectedIntervention?._id}
              onChange={(e) => {
                let intervention = filteredList.find(
                  (inter) => inter._id == e.target.value
                );
                setSelectedIntervention(intervention);
              }}
            >
              <option value="">Select Intervention</option>
              {filteredList &&
                filteredList.map((intervention, index) => {
                  return (
                    <option key={index} value={intervention._id}>
                      {" "}
                      {intervention.name}{" "}
                    </option>
                  );
                })}
            </select>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>Schedule</h2>
            <Button
              variant="contained"
              className="add_btn"
              style={{ marginTop: "20px" }}
              //   margin={"10px 0px"}
              //  color="#556B2F"
              onClick={() => {
                // boundArrayHelpers.push({
                //   label: "",
                //   value: "",
                // });
                // dispatch(setSchedule({ index: index, type: "add" }));

                setSchedule([...schedule, moment().format("HH:mm")]);
              }}
              disabled={!selectedIntervention}
            >
              Add
            </Button>
          </div>
          {schedule?.map((item, i) => (
            <Box
              className={`row ${i % 2 == 0 && "bg-light"}`}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              key={i}
              paddingY={"10px"}
              borderBottom={"2px solid #ccc"}
              width={"100%"}
              gap={"5px"}
              flexWrap={"wrap"}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="schedule-row">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopTimePicker
                    label="Schedule Time"
                    value={hhMMTotimeStamp(item || moment().format("HH:mm"))}
                    ampm={false}
                    onChange={(newValue) => {
                      let value = timeStampToHHMMstring(newValue);
                      let tempSchedule = [...schedule];
                      tempSchedule[i] = value
                      // tempSchedule.splice(i, 1, value);
                      setSchedule(tempSchedule);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                <Box>
                  <i
                    className="fa-solid fa-trash trash-btn"
                    onClick={() => {
                      let tmpSchedule = [...schedule];
                      tmpSchedule.splice(i, 1);
                      setSchedule(tmpSchedule);
                    }}
                  ></i>
                </Box>
              </div>
            </Box>
          ))}
        </>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleClick("close")}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={!selectedIntervention || !schedule.length}
          onClick={() => handleClick("Add")}
        >
          Complete
        </Button>
        {/* <Button
            variant="contained"
            type="submit"
            // onClick={() => handleClick("save")}
          >
            Save
          </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default InterventionDialogue;
