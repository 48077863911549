import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authSlice from "./slice/authSlice";
import usersSlice from "./slice/usersSlice";
import categorySlice from "./slice/categorySlice";
import goalSlice from "./slice/goalSlice";
import interventionSlice from "./slice/interventionSlice";
import variableSlice from "./slice/variableSlice";
import overviewSlice from "./slice/dashbaordSlice";
import trialSlice from "./slice/trialSlice";
// import interventionSlice from "./slice/varSlice";
import logger from "redux-logger";
import reportSlice from "./slice/reportSlice";
import adminTrialSlice from "./slice/adminTrialSlice";
export default configureStore({
  reducer: {
    auth: authSlice,
    users: usersSlice,
    category: categorySlice,
    goal: goalSlice,
    intervention: interventionSlice,
    variable: variableSlice,
    overview: overviewSlice,
    trial: trialSlice,
    report: reportSlice,
    admin_trial: adminTrialSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
