import React from "react";
import reportContentObject from "../../../helpers/reportContentObject";

function Content({ content }) {
  let renderContent =
    content &&
    Object.entries(reportContentObject[content?.type]["fields"]).map(
      ([key, valuePath], index) => {
        return (
          <div key={index}>
            <strong>{key} </strong> : {eval(valuePath)}
          </div>
        );
      }
    );
  return (
    <div>
      <div>
        <div className="report-content-for">
          Content Reported for{" "}
          <strong>{reportContentObject[content?.type]?.title}</strong>
        </div>
      </div>
      <div className="content-fields">{renderContent}</div>
    </div>
  );
}

export default Content;
