import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../helpers/RestClient";
import {
  encrypt,
  decrypt,
  showSuccessToast,
  showErrorToast,
} from "../helpers/common";
import GLOBALS from "../constants";
const { MESSAGES } = GLOBALS;
export const goalList = createAsyncThunk(
  "/goal",
  async ({ page, search }, { dispatch }) => {
    try {
      const response = await api.goal(page, search);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(setGoalList(decrypt_data.result));
      } else {
        showErrorToast(decrypt_data.message);
      }
    } catch (err) {
      showErrorToast(err?.message || "Internal Server Error");
      //return rejectWithValue(err.response.data);
    }
  }
);

export const editGoal = createAsyncThunk(
  "/goalEdit",
  async ({ values }, { dispatch, getState }) => {
    let id = getState().goal?.current_goal._id;
    try {
      const response = await api.goalEditSubmit(id, values);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(goalList({ page: 1, search: "" }));
        dispatch(setCurrentGoal({}));
        showSuccessToast(MESSAGES.Goal_EDIT);
      } else {
        showErrorToast(decrypt_data.message);
      }
    } catch (err) {
      showErrorToast(err?.message || "Internal Server Error");
    }
  }
);

export const addGoal = createAsyncThunk(
  "/goalAdd",
  async ({ values }, { dispatch, getState }) => {
    let id = getState().goal?.current_goal._id;
    try {
      const response = await api.goalManagament(values);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(goalList({ page: 1, search: "" }));
        dispatch(setCurrentGoal({}));
        showSuccessToast(MESSAGES.GOAL_ADD);
      } else {
        showErrorToast(decrypt_data.message);
      }
    } catch (err) {
      showErrorToast(err?.message || "Internal Server Error");
    }
  }
);

export const getAdminGoalList = createAsyncThunk(
  "/goal/admin",
  async ({ categoryId }, { dispatch }) => {
    try {
      const response = await api.goalForAdmin(categoryId);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(setGoalList(decrypt_data.result));
        // return decrypt_data.result;
      }
      return;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const deleteGoal = createAsyncThunk(
  "/categoryDelete",
  async (data, { dispatch, getState }) => {
    try {
      const response = await api.goalDelete(data.id);
      const decrypt_data = await decrypt(response.data.data);
      if (decrypt_data.code == 200) {
        dispatch(goalList({ page: 1, search: "" }));
        dispatch(setCurrentGoal({}));
        showSuccessToast(MESSAGES.GOAL_DEL);
      } else {
        showErrorToast(decrypt_data.message);
      }
    } catch (err) {
      showErrorToast(err?.message || "Internal Server Error");
    }
  }
);

const initialState = {
  user: null,
  error: "",
  loading: false,
};
const goalSlice = createSlice({
  name: "goals",
  initialState: {
    all_goal: [],
    current_goal: {},
    // loading: false
  },
  reducers: {
    setGoalList: (state, action) => {
      state.all_goal = action.payload;
    },
    setCurrentGoal: (state, action) => {
      state.current_goal = action.payload;
    },
  },
  extraReducers: {},
});

export const { setGoalList, setCurrentGoal } = goalSlice.actions;
export default goalSlice.reducer;
