import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Container,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function TrialPreview(props) {
  let { open, data, onClose, onOk } = props;
  const { admin_current_trial } = useSelector((store) => ({
    admin_current_trial: store.admin_trial.admin_current_trial,
  }));
  const characters = ["A", "B", "C", "D", "E"];
  const [previewData, setPreviewData] = useState([]);
  useEffect(() => {
    const arr = [];
    const interventionArr =
      admin_current_trial?.intervention.length > 1
        ? admin_current_trial?.intervention
        : [
          ...admin_current_trial?.intervention,
          {
            ...admin_current_trial?.intervention[0],
            name: `Without ${admin_current_trial?.intervention[0].name}`,
          },
        ];
    for (let i = 0; i < Number(admin_current_trial?.phaseNumber); i++) {
      interventionArr?.map((o, index) => {
        const obj = { ...o };
        obj["days"] = admin_current_trial?.phaseDuration;
        obj["pattern"] = characters[index];
        arr.push(obj);
      });
    }
    if (admin_current_trial?.phasePattern === "alter") {
      setPreviewData(arr);
    } else {
      const formattedData = [...arr];
      for (const [i, o] of arr.entries()) {
        if (i !== 0 && i % 2 == 0 && i / 2 !== 2 && i / 2 !== 4) {
          if (i == 8) {

          }
          formattedData[i] = arr[i + 1];
          formattedData[i + 1] = o;
        }
      }
      setPreviewData(formattedData);
    }

  }, [admin_current_trial]);
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle variant="body">Preview</DialogTitle>
      <DialogContent>
        <Container
          maxWidth="md"
          style={{ textAlign: "center" }}
          className="trialcontainer"
        >
          <div className="contentBox intervention-container">
            <div className="intervention-list-container">
              <ul>
                <li>
                  {previewData?.map((item, index) => {
                    return (
                      <>
                        <Box className="intervensionList" sx={{ my: "5px" }}>
                          {/* <div className="tag">{intervention.tag}</div> */}

                          <Box
                            sx={{ paddingLeft: "1em", width: "10%" }}
                            className="name"
                          >
                            <p>{item.pattern}</p>
                          </Box>
                          <Box sx={{ width: "60%" }} className="name">
                            <p>{item?.name}</p>
                          </Box>
                          <Box width={"30%"} className="name" color={"#fff"}>
                            {item?.schedule.join(", ")}
                          </Box>
                          <Box width={"30%"} className="name" color={"#fff"}>
                            {`${item?.days} days`}
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                </li>
              </ul>
            </div>
          </div>
          <div
            className="text-right"
            style={{
              textAlign: "end",
              padding: "0 1rem",
            }}
          >
            <p>
              Total Days:{" "}
              {Number(admin_current_trial?.phaseNumber) *
                (Number(admin_current_trial?.phaseDuration) * 2)}
            </p>
          </div>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={() => onOk()}>
          Submit
        </Button>
        {/* <Button
            variant="contained"
            type="submit"
            // onClick={() => handleClick("save")}
          >
            Save
          </Button> */}
      </DialogActions>
    </Dialog>
  );
}
