import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"

const LogutModel = ({ open, handleClose, handleClick }) => {
    return (
        <Dialog open={open}>
            {/* <DialogTitle variant="body">
                Confirm Logut?
            </DialogTitle> */}
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        minWidth: "450px",
                        minHeight: "70px",
                        justifyContent: "center",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <div
                        className="border-top border-info border-3 bg-white mt-3 p-2 "
                        style={{ width: "100%", fontSize: "large" }}
                    >
                        Are you sure you want to logout?
                    </div>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose("close")}>Cancel</Button>
                <Button
                    variant="contained"
                    type="button"
                    onClick={() => handleClick()}
                >
                    Logout
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default LogutModel