import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CryptoJS = require("crypto-js");
const hippaKey = "Mafda%$342Mifka%3,mafdimkK453K5%^kma";

//
const encrypt = async (data) => {
  return new Promise((resolve) => {
    const ciphertext = CryptoJS.AES.encrypt(data, hippaKey).toString();
    resolve(ciphertext);
  }).catch((err) => {
  });
};

const decrypt = async (data) => {
  return new Promise((resolve) => {
    const bytes = CryptoJS.AES.decrypt(data, hippaKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    resolve(decryptedData);
  }).catch((err) => {
  });
};

const showSuccessToast = (msg) => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const showErrorToast = (msg) => {
  try {
    toast.error(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } catch (err) {
    alert(err?.message);
  }
};
export { encrypt, decrypt, showSuccessToast, showErrorToast };
