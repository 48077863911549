import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { styled } from "@mui/material/styles";
import frtSvg from "../../../assets/frontArrow.svg";
import bckSvg from "../../../assets/backArrow.svg";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Pagination,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactPaginate from "react-paginate";
import {
  deleteUser,
  setCurrentUser,
  userData,
  usersConvert,
} from "../../../slice/usersSlice";
import { Box } from "@mui/system";
import DeleteModal from "../../common/DeleteModal";
import { getAdminTrials } from "../../../slice/adminTrialSlice";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const AdminTrialList = (props) => {
  let dispatch = useDispatch();
  const { admin_all_trial } = useSelector((store) => ({
    admin_all_trial: store.admin_trial.admin_all_trial,
  }));

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [trialList, setTrialList] = useState([]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
    // fetchData(data.selected + 1, "");
    dispatch(
      getAdminTrials({
        page: data?.selected + 1,
        limit: 10,
        status: "all",
      })
    );
  };

  const handlePageClickEvent = (page) => {
    // props.setPage(page);
    setCurrentPage(page);
    // setDummyReload(new Date().toLocaleTimeString());
    // fetchData(data.selected + 1, "");
    dispatch(
      getAdminTrials({
        page: page,
        limit: 10,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getAdminTrials({
        page: currentPage,
        limit: 10,
      })
    );
  }, []);
  useEffect(() => {
    setTrialList(admin_all_trial.list);
    setPageCount(Math.ceil(admin_all_trial?.count / 10));
  }, [admin_all_trial]);

  return (
    <div>
      <Grid item xs={6} sm={6} md={9}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow sx={{ color: "red" }}>
                <StyledTableCell align="left">Sr. No</StyledTableCell>
                <StyledTableCell align="left">Name</StyledTableCell>
                <StyledTableCell align="left">Idea</StyledTableCell>
                <StyledTableCell align="left">Category</StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trialList &&
                trialList?.map((trial, index) => {
                  // AdminPanelSettingsIcon;

                  return (
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        {(currentPage - 1) * 10 + index + 1}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {trial.name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {trial.idea}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {trial.category.name}
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        View
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {pageCount && pageCount > 1 ? (
          <div className="myPaginationBottom">
            <Pagination
              totalPage={pageCount}
              currentActionPage={currentPage}
              previousIcon={bckSvg}
              nextIcon={frtSvg}
              defaultActivePage={currentPage}
              onPageChange={handlePageClick}
              onhandlePageChange={handlePageClickEvent}
            />
          </div>
        ) : (
          <></>
        )}
      </Grid>
      {/* <DeleteModal
        isOpen={open}
        handleClose={handleClickOpen}
        onDelete={onDelete}
      /> */}
    </div>
  );
};

export default AdminTrialList;
