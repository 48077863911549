import React from "react";
import { ToastContainer } from "react-toastify";
import Header from "../../components/common/Header";
import SideBar from "../../components/common/SideBar";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userData } from "../../slice/usersSlice";
import EditAdminUser from "../../components/dashboard/adminUser/EditAdminUser";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import AdminTrialList from "../../components/dashboard/adminTrial/AdminTrialList";
import { useNavigate } from "react-router-dom";
import { setAdminCurrentTrial } from "../../slice/adminTrialSlice";
import { showSuccessToast } from "../../helpers/common";
import TrialList from "../../components/dashboard/adminTrial/TrialList";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
const Trials = () => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const { data } = useSelector((store) => ({
    data: store.users.all_users,
  }));
  const [isEditOpen, setEditOpen] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [user_list, setUserList] = useState([]);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSearchData(e.target.value);
    // setCurrentPage(1);
    dispatch(userData({ page: 1, search: e.target.value, type: "admin" }));
  };
  useEffect(() => {
    dispatch(userData({ page: 1, search: "", type: "admin" }));
  }, []);
  useEffect(() => {
    setUserList(data);
  }, [data]);
  return (
    <>
      <div className="dashboard-wrapper d-flex flex-column flex-fill overflow-auto">
        <Header setToggle={setToggle} toggle={toggle} />
        <div className="inner-wrapper">
          <div
            className={`left-col d-flex flex-column  ${toggle ? "collapseSidebar" : ""
              } `}
          >
            <SideBar setToggle={setToggle} toggle={toggle}></SideBar>
          </div>

          <div
            className={`right-col d-flex flex-column ${toggle ? "expandDashboard" : ""
              }`}
          >
            <Typography variant="h4" class="page_title">
              Trial Management
            </Typography>
            {/* <Box
              justifyContent={"space-between"}
              className="d-flex justify-content-between searchBox"
            > */}
            {/* <div className="d-flex searchBox">
              <input
                className="searchBarWithButton"
                placeholder="Search...."
                type="text"
                // disabled
                value={searchData}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <Button
                variant="contained"
                className="add_btn"
                // sx={{
                //   "&:hover": {
                //     cursor: "no-drop",
                //   },
                // }}
                // color="#556B2F"
                onClick={() => {
                  dispatch(setAdminCurrentTrial({
                    category: "",
                    idea: "",
                    image: "",
                    intervention: [],
                    isPublic: true,
                    name: "",
                    phaseDuration: 7,
                    phaseNumber: 1,
                    phasePattern: "test",
                    variables: []
                  }))
                  navigate("/admin-trial/add");
                }}
              >
                Add
              </Button>
            </div> */}
            <Tabs>
              <TabList>
                <Tab>Active Trials</Tab>
                <Tab>Inactive Trials</Tab>
              </TabList>

              <TabPanel>
                <TrialList status={false} />
              </TabPanel>
              <TabPanel>
                <TrialList status={true} />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Trials;
